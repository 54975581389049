import { Text, Box, Button, Flex } from "@chakra-ui/react";
import BaseImage from "@components/BaseImage";

import { Connector, useConnect } from "wagmi";

import {
  HIDDEN_WALLETS,
  walletIconMapping,
  walletLabelMapping,
} from "../../../../constants/common";

interface WalletListProps {
  onConnectWallet(data: Connector): void;
}

const WalletList = ({ onConnectWallet }: WalletListProps) => {
  const { connectors } = useConnect();

  return (
    <Box maxH="480px" overflow="auto" mt="0.5rem" className="scroll-container">
      <Box
        // pointerEvents={!isChecked ? "none" : "inherit"}
        // opacity={isChecked ? "1" : "0.5"}
        mt="1rem"
      >
        <Flex flexWrap="wrap" gap="1rem">
          {connectors &&
            connectors
              .filter((wallet: any) => !HIDDEN_WALLETS.includes(wallet.id))
              .map((data: any) => {
                const { id } = data;
                return (
                  <Box
                    as={Button}
                    key={id}
                    bg="backgroundCanvas"
                    onClick={() => onConnectWallet(data)}
                    width={{ base: "100%", md: "224px" }}
                    outline="none"
                    p="0"
                    h="72px"
                    _hover={{
                      background: "backgroundCanvas",
                      // color: "brand.textLight",
                    }}
                  >
                    <Flex
                      width="100%"
                      gap="0.5rem"
                      alignItems="center"
                      p="1rem 1.5rem"
                    >
                      <Box height="40px" borderRadius="50%" overflow="hidden">
                        <BaseImage
                          src={walletIconMapping[id.toLowerCase()]}
                          alt={`${id} Logo`}
                          width={40}
                          height={40}
                        />
                      </Box>
                      <Text
                        color="textPrimary"
                        fontSize="16px"
                        fontWeight="600"
                      >
                        {walletLabelMapping[id.toLowerCase()]}
                      </Text>
                    </Flex>
                  </Box>
                );
              })}
        </Flex>
      </Box>
    </Box>
  );
};

export default WalletList;
