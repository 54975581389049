import { Box, Center, Text } from "@chakra-ui/react";

import CloseIcon from "@components/CloseIcon";
import ModalDrawer from "@components/ModalDrawer";
import Stepper from "@components/Stepper";
import SuccessErrorModalBodyTemplate from "@components/SuccessErrorModalBodyTemplate";

import { STEPS_STADER_LEDGER_TXS, TransactionType } from "@constants/common";
import { useMemo } from "react";

interface StaderLedgerTxModalProps {
  isOpen: boolean;
  closeAlert: () => void;
  error?: string;
  activeIndex: number;
  hasUserDenied?: boolean;
}

const StaderLedgerTxModal = ({
  isOpen,
  activeIndex,
  error,
  hasUserDenied,
  closeAlert,
}: StaderLedgerTxModalProps) => {
  const renderContent = () => {
    if (error) {
      if (hasUserDenied) {
        return (
          <SuccessErrorModalBodyTemplate
            transactionType={TransactionType.WARNING}
            modalSubTitle="User denied transaction signature. Please try again"
            modalTitle="Transaction denied"
          />
        );
      }
      return (
        <SuccessErrorModalBodyTemplate
          transactionType={TransactionType.ERROR}
          modalTitle="Transaction Failed"
          modalSubTitle={error ? "" : error}
        />
      );
    }
    return (
      <Box mt="2rem" w="100%">
        <Stepper
          steppersData={STEPS_STADER_LEDGER_TXS}
          activeIndex={activeIndex}
        />
      </Box>
    );
  };

  const title = useMemo(() => {
    if (activeIndex >= STEPS_STADER_LEDGER_TXS.length) {
      return "Transaction Successful";
    }
    return "Transaction In-progress";
  }, [activeIndex]);

  return (
    <ModalDrawer isOpen={isOpen} closeAlert={closeAlert}>
      <Center
        padding={{ base: "0 1rem 2.5rem", md: "2.5rem 3rem" }}
        fontWeight="600"
        flexDirection="column"
        width="100%"
      >
        <CloseIcon onClose={closeAlert} />
        {!error ? (
          <>
            <Text
              fontSize={{
                base: "20px",
                md: "24px",
              }}
              lineHeight="28px"
            >
              {title}
            </Text>

            {activeIndex < STEPS_STADER_LEDGER_TXS.length ? (
              <Text
                mt="0.5rem"
                fontSize={{
                  base: "12px",
                  md: "14px",
                }}
                fontWeight="400"
                textAlign="center"
                color="textSecondary"
              >
                Do not refresh or close before completing all steps.
              </Text>
            ) : null}
          </>
        ) : null}

        {renderContent()}
      </Center>
    </ModalDrawer>
  );
};

export default StaderLedgerTxModal;
