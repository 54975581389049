import { createSlice } from "@reduxjs/toolkit";

export interface StakeStateProps {
  approveTokenLoading: boolean;
  approveTokenXLoading: boolean;
  isStaking: boolean;
  isUnstaking: boolean;
  isClaiming: boolean;
  erc20Allowance: number | null;
  isTransactionProcessing: boolean;
}

export const initialStakeState: StakeStateProps = {
  approveTokenLoading: false,
  approveTokenXLoading: false,
  isStaking: false,
  erc20Allowance: null,
  isTransactionProcessing: false,
  isUnstaking: false,
  isClaiming: false,
};

export const stakeSlice = createSlice({
  name: "stake",
  initialState: initialStakeState,
  reducers: {
    updateStakeData: (state: any, action: any) => {
      for (const [key, value] of Object.entries(action.payload)) {
        state[key] = value;
      }
    },
    clearStakeData: (state: any) => {
      for (const [key, value] of Object.entries(initialStakeState)) {
        state[key] = value;
      }
    },
  },
});

export const { updateStakeData, clearStakeData }: any = stakeSlice.actions;

export default stakeSlice.reducer;
