export const BANNER_DATA = {
  text: "",
  cta_text: "",
  cta_link: "",
};

export const BANNER_IMAGES = [
  "/assets/banner1.png",
  "/assets/banner2.png",
  "/assets/banner3.png",
  // "/assets/banner4.png",
  // "/assets/banner-blast.png",
];
