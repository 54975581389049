import { Abi, Chain, erc20Abi } from "viem";

// etherem abis
import lrtDepositPoolAbi from "@abi/ethereum/LRTDepositPool.json";
import kepAbi from "@abi/ethereum/KEP.json";
import vaultJson from "@abi/Vault.json";
import rsETHAdapterJson from "@abi/rsETHAdapter.json";
import merkleDistributorAbi from "@abi/ethereum/MerkleDistributor.json";
import withdrawalManagerAbi from "@abi/ethereum/LRTWithdrawalManager.json";
import unstakingVaultAbi from "@abi/ethereum/LRTUnstakingVault.json";

// arbitrum abis
import lrtDepositPoolAbiArb from "@abi/arbitrum/LRTDepositPool.json";

// common abi for blast scroll optimism
import lrtDepositPoolAbiCommon from "@abi/common/LRTDepositPool.json";

// manta abis
import tokenWrapperAbi from "@abi/common/wrseth.json";

// xlayer abis
import lrtDepositPoolAbiXL from "@abi/xlayer/LRTDepositPool.json";

import {
  arbitrum,
  base,
  blast,
  linea,
  mainnet,
  manta,
  mode,
  optimism,
  scroll,
  zkSync,
  xLayer,
} from "viem/chains";
import { Hash } from "viem";

export const ETHX = "ETHx";
export const STETH = "stETH";
export const SFRXETH = "sfrxETH";
export const ETH = "ETH";
export const OKB = "OKB";
export const WETH = "wETH";
export const WSTETH = "wstETH";

export const enum TAGS {
  KELP_MILES = "kelpMiles",
  EL_POINTS = "elPoints",
  EXTRA_EL_POINTS = "extraELPoints",
}

export const LST_TAGS = {
  [TAGS.KELP_MILES]: {
    title: "Kelp Miles",
    style: {
      backgroundColor: "#5D8A8A",
    },
  },
  [TAGS.EL_POINTS]: {
    title: "EL Points",
    style: {
      backgroundColor: "#78A5CF",
    },
  },
  [TAGS.EXTRA_EL_POINTS]: {
    title: "+25 EL Points/rsETH",
    style: {
      backgroundColor: "#DF60AC",
    },
  },
};

export type TokenDetails = {
  [x: string]: {
    symbol: string;
    decimals: number;
    address: Hash;
    abi: Abi;
    priority?: number;
    unstakePriority?: number;
    tags: Array<TAGS> | [];
    isLiquidStakeSupported?: boolean;
  };
};

export type ContractDetails = {
  [x: string]: {
    address: Hash;
    abi: Abi;
  };
};

export type ContractsConfigMap = {
  [x: Chain["id"]]: {
    chain: Chain;
    interactionPools: ContractDetails;
    tokens: TokenDetails;
    nativeToken: {
      symbol: string;
      decimals: number;
      address: string;
      tags: Array<TAGS> | [];
      isRestakeSupported: boolean;
      unstakePriority?: number;
    };
    xtoken: {
      symbol: string;
      decimals: number;
      address: Hash;
      abi: Abi;
    };
  };
};

export const ledgerSupportedChains = [mainnet, arbitrum, optimism];

export const contractConfig: ContractsConfigMap = {
  [mainnet.id]: {
    chain: mainnet,
    nativeToken: {
      symbol: ETH,
      decimals: 18,
      address: "0xeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeee",
      tags: [TAGS.KELP_MILES, TAGS.EL_POINTS],
      isRestakeSupported: true,
      unstakePriority: 0,
    },
    xtoken: {
      address: "0xA1290d69c65A6Fe4DF752f95823fae25cB99e5A7",
      abi: erc20Abi,
      symbol: "rsETH",
      decimals: 18,
    },

    tokens: {
      [STETH]: {
        address: "0xae7ab96520DE3A18E5e111B5EaAb095312D7fE84",
        abi: erc20Abi,
        symbol: "stETH",
        decimals: 18,
        tags: [TAGS.KELP_MILES, TAGS.EL_POINTS],
        unstakePriority: -1,
      },
      [ETHX]: {
        address: "0xA35b1B31Ce002FBF2058D22F30f95D405200A15b",
        abi: erc20Abi,
        symbol: "ETHx",
        decimals: 18,
        tags: [TAGS.KELP_MILES],
        priority: -1,
        isLiquidStakeSupported: true,
      },
      [SFRXETH]: {
        address: "0xac3e018457b222d93114458476f3e3416abbe38f",
        abi: erc20Abi,
        symbol: "sfrxETH",
        decimals: 18,
        tags: [],
        unstakePriority: -2,
      },
    },
    interactionPools: {
      lrtDepositPool: {
        address: "0x036676389e48133b63a802f8635ad39e752d375d",
        abi: lrtDepositPoolAbi as Abi,
      },
      kep: {
        address: "0x8E3A59427B1D87Db234Dd4ff63B25E4BF94672f4",
        abi: kepAbi as Abi,
      },
      merkleDistributor: {
        address: "0x2DDB11443bD9Ceb92d4951A05f55eb7096EB53d3",
        abi: merkleDistributorAbi as Abi,
      },
      lrtWithdrawalManager: {
        address: "0x62De59c08eB5dAE4b7E6F7a8cAd3006d6965ec16",
        abi: withdrawalManagerAbi as Abi,
      },
      lrtUnstakingVault: {
        address: "0xc66830E2667bc740c0BED9A71F18B14B8c8184bA",
        abi: unstakingVaultAbi as Abi,
      },
    },
  },
  [arbitrum.id]: {
    chain: arbitrum,
    nativeToken: {
      symbol: ETH,
      decimals: 18,
      address: "0xeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeee",
      tags: [TAGS.KELP_MILES, TAGS.EL_POINTS],
      isRestakeSupported: true,
    },
    xtoken: {
      address: "0x4186bfc76e2e237523cbc30fd220fe055156b41f",
      abi: erc20Abi,
      symbol: "rsETH",
      decimals: 18,
    },

    tokens: {
      [WSTETH]: {
        address: "0x5979D7b546E38E414F7E9822514be443A4800529",
        abi: erc20Abi,
        symbol: WSTETH,
        decimals: 18,
        tags: [TAGS.KELP_MILES, TAGS.EL_POINTS],
        unstakePriority: -1,
      },
      [ETHX]: {
        address: "0xED65C5085a18Fa160Af0313E60dcc7905E944Dc7",
        abi: erc20Abi,
        symbol: ETHX,
        decimals: 18,
        tags: [TAGS.KELP_MILES],
        priority: -1,
        isLiquidStakeSupported: true,
      },
    },
    interactionPools: {
      lrtDepositPool: {
        address: "0x376A7564AF88242D6B8598A5cfdD2E9759711B61",
        abi: lrtDepositPoolAbiArb as Abi,
      },
    },
  },
  [mode.id]: {
    chain: mode,
    nativeToken: {
      symbol: ETH,
      decimals: 18,
      address: "0xeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeee",
      tags: [TAGS.KELP_MILES, TAGS.EL_POINTS],
      isRestakeSupported: true,
    },
    xtoken: {
      address: "0xe7903B1F75C534Dd8159b313d92cDCfbC62cB3Cd",
      abi: erc20Abi,
      symbol: "wrsETH",
      decimals: 18,
    },

    tokens: {},
    interactionPools: {
      lrtDepositPool: {
        address: "0xbDf612E616432AA8e8D7d8cC1A9c934025371c5C",
        abi: lrtDepositPoolAbiCommon as Abi,
      },
    },
  },
  [blast.id]: {
    chain: blast,
    nativeToken: {
      symbol: ETH,
      decimals: 18,
      address: "0xeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeee",
      tags: [TAGS.KELP_MILES, TAGS.EL_POINTS],
      isRestakeSupported: true,
    },
    xtoken: {
      address: "0xe7903B1F75C534Dd8159b313d92cDCfbC62cB3Cd",
      abi: erc20Abi,
      symbol: "wrsETH",
      decimals: 18,
    },

    tokens: {},
    interactionPools: {
      lrtDepositPool: {
        address: "0x1558959f1a032F83f24A14Ff539944A926C51bdf",
        abi: lrtDepositPoolAbiCommon as Abi,
      },
    },
  },
  [scroll.id]: {
    chain: scroll,
    nativeToken: {
      symbol: ETH,
      decimals: 18,
      address: "0xeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeee",
      tags: [TAGS.KELP_MILES, TAGS.EL_POINTS],
      isRestakeSupported: true,
    },
    xtoken: {
      address: "0xa25b25548B4C98B0c7d3d27dcA5D5ca743d68b7F",
      abi: erc20Abi,
      symbol: "wrsETH",
      decimals: 18,
    },

    tokens: {},
    interactionPools: {
      lrtDepositPool: {
        address: "0xb80deaecd7F4Bca934DE201B11a8711644156a0a",
        abi: lrtDepositPoolAbiCommon as Abi,
      },
    },
  },
  [optimism.id]: {
    chain: optimism,
    nativeToken: {
      symbol: ETH,
      decimals: 18,
      address: "0xeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeee",
      tags: [TAGS.KELP_MILES, TAGS.EL_POINTS],
      isRestakeSupported: true,
    },
    xtoken: {
      address: "0x87eEE96D50Fb761AD85B1c982d28A042169d61b1",
      abi: erc20Abi,
      symbol: "wrsETH",
      decimals: 18,
    },

    tokens: {},
    interactionPools: {
      lrtDepositPool: {
        address: "0xaAA687e218F9B53183A6AA9639FBD9D6e69EcB73",
        abi: lrtDepositPoolAbiCommon as Abi,
      },
    },
  },
  [base.id]: {
    chain: base,
    nativeToken: {
      symbol: ETH,
      decimals: 18,
      address: "0xeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeee",
      tags: [TAGS.KELP_MILES, TAGS.EL_POINTS],
      isRestakeSupported: true,
    },
    xtoken: {
      address: "0xEDfa23602D0EC14714057867A78d01e94176BEA0",
      abi: erc20Abi,
      symbol: "wrsETH",
      decimals: 18,
    },

    tokens: {},
    interactionPools: {
      lrtDepositPool: {
        address: "0x291088312150482826b3A37d5A69a4c54DAa9118",
        abi: lrtDepositPoolAbiCommon as Abi,
      },
    },
  },
  [linea.id]: {
    chain: linea,
    nativeToken: {
      symbol: ETH,
      decimals: 18,
      address: "0xeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeee",
      tags: [TAGS.KELP_MILES, TAGS.EL_POINTS],
      isRestakeSupported: true,
    },
    xtoken: {
      address: "0xD2671165570f41BBB3B0097893300b6EB6101E6C",
      abi: erc20Abi,
      symbol: "wrsETH",
      decimals: 18,
    },

    tokens: {},
    interactionPools: {
      lrtDepositPool: {
        address: "0x057297e44a3364139edcf3e1594d6917ed7688c2",
        abi: lrtDepositPoolAbiCommon as Abi,
      },
    },
  },
  [zkSync.id]: {
    chain: zkSync,
    nativeToken: {
      symbol: ETH,
      decimals: 18,
      address: "0xeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeee",
      tags: [TAGS.KELP_MILES, TAGS.EL_POINTS],
      isRestakeSupported: true,
    },
    xtoken: {
      address: "0xd4169E045bcF9a86cC00101225d9ED61D2F51af2",
      abi: erc20Abi,
      symbol: "wrsETH",
      decimals: 18,
    },
    tokens: {},
    interactionPools: {
      lrtDepositPool: {
        address: "0x41b300f5A619973b20931f0944C85DB229d5E27f",
        abi: lrtDepositPoolAbiCommon as Abi,
      },
    },
  },
  [xLayer.id]: {
    chain: xLayer,
    nativeToken: {
      symbol: OKB,
      decimals: 18,
      address: "0xeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeee",
      tags: [TAGS.KELP_MILES, TAGS.EL_POINTS],
      isRestakeSupported: false,
    },
    xtoken: {
      address: "0x5A71f5888EE05B36Ded9149e6D32eE93812EE5e9",
      abi: erc20Abi,
      symbol: "wrsETH",
      decimals: 18,
    },
    tokens: {
      [WETH]: {
        address: "0x5A77f1443D16ee5761d310e38b62f77f726bC71c",
        abi: erc20Abi,
        symbol: WETH,
        decimals: 18,
        tags: [],
      },
    },
    interactionPools: {
      lrtDepositPool: {
        address: "0x4Ef626efE4a3A279a9DC7e7a91C1c9CaaAE8e159",
        abi: lrtDepositPoolAbiXL as Abi,
      },
    },
  },
};

export type SwapContractsConfigMap = {
  [x: Chain["id"]]: {
    chain: Chain;
    tokens: {
      rsETH: { symbol: string; decimals: number; address: Hash; abi: Abi };
      wrsETH: { symbol: string; decimals: number; address: Hash; abi: Abi };
    };
  };
};

export const vaultsContractConfig = {
  interactionPool: {
    address: "0xe1B4d34E8754600962Cd944B535180Bd758E6c2e" as `0x${string}`,
    abi: vaultJson as Abi,
  },
  rsETHAdapterPool: {
    address: "0xbf28c9fcb12a97441488f9c68faa49811a98688a" as `0x${string}`,
    abi: rsETHAdapterJson as Abi,
  },
  xToken: {
    address: "0xe1B4d34E8754600962Cd944B535180Bd758E6c2e" as `0x${string}`,
    abi: erc20Abi,
    symbol: "agETH",
    decimals: 18,
  },
};

export const swappingContractsConfig: SwapContractsConfigMap = {
  [manta.id]: {
    chain: manta,
    tokens: {
      rsETH: {
        address: "0x4186BFC76E2E237523CBC30FD220FE055156b41F",
        abi: erc20Abi,
        symbol: "rsETH",
        decimals: 18,
      },
      wrsETH: {
        address: "0x9dd4f9EeE9B05D1ebec1d4aAE7Ae9F5d8D235CD4",
        abi: tokenWrapperAbi as Abi,
        symbol: "wrsETH",
        decimals: 18,
      },
    },
  },
  [mode.id]: {
    chain: mode,
    tokens: {
      rsETH: {
        address: "0x4186BFC76E2E237523CBC30FD220FE055156b41F",
        abi: erc20Abi,
        symbol: "rsETH",
        decimals: 18,
      },
      wrsETH: {
        address: "0xe7903B1F75C534Dd8159b313d92cDCfbC62cB3Cd",
        abi: tokenWrapperAbi as Abi,
        symbol: "wrsETH",
        decimals: 18,
      },
    },
  },
  [blast.id]: {
    chain: blast,
    tokens: {
      rsETH: {
        address: "0x4186BFC76E2E237523CBC30FD220FE055156b41F",
        abi: erc20Abi,
        symbol: "rsETH",
        decimals: 18,
      },
      wrsETH: {
        address: "0xe7903B1F75C534Dd8159b313d92cDCfbC62cB3Cd",
        abi: tokenWrapperAbi as Abi,
        symbol: "wrsETH",
        decimals: 18,
      },
    },
  },
  [scroll.id]: {
    chain: scroll,
    tokens: {
      rsETH: {
        address: "0x65421ba909200b81640d98B979d07487C9781B66",
        abi: erc20Abi,
        symbol: "rsETH",
        decimals: 18,
      },
      wrsETH: {
        address: "0xa25b25548B4C98B0c7d3d27dcA5D5ca743d68b7F",
        abi: tokenWrapperAbi as Abi,
        symbol: "wrsETH",
        decimals: 18,
      },
    },
  },
  [optimism.id]: {
    chain: optimism,
    tokens: {
      rsETH: {
        address: "0x4186BFC76E2E237523CBC30FD220FE055156b41F",
        abi: erc20Abi,
        symbol: "rsETH",
        decimals: 18,
      },
      wrsETH: {
        address: "0x87eEE96D50Fb761AD85B1c982d28A042169d61b1",
        abi: tokenWrapperAbi as Abi,
        symbol: "wrsETH",
        decimals: 18,
      },
    },
  },
  [base.id]: {
    chain: base,
    tokens: {
      rsETH: {
        address: "0x1Bc71130A0e39942a7658878169764Bbd8A45993",
        abi: erc20Abi,
        symbol: "rsETH",
        decimals: 18,
      },
      wrsETH: {
        address: "0xEDfa23602D0EC14714057867A78d01e94176BEA0",
        abi: tokenWrapperAbi as Abi,
        symbol: "wrsETH",
        decimals: 18,
      },
    },
  },
  [linea.id]: {
    chain: linea,
    tokens: {
      rsETH: {
        address: "0x4186bfc76e2e237523cbc30fd220fe055156b41f",
        abi: erc20Abi,
        symbol: "rsETH",
        decimals: 18,
      },
      wrsETH: {
        address: "0xD2671165570f41BBB3B0097893300b6EB6101E6C",
        abi: tokenWrapperAbi as Abi,
        symbol: "wrsETH",
        decimals: 18,
      },
    },
  },
  [zkSync.id]: {
    chain: zkSync,
    tokens: {
      rsETH: {
        address: "0x6bE2425C381eb034045b527780D2Bf4E21AB7236",
        abi: erc20Abi,
        symbol: "rsETH",
        decimals: 18,
      },
      wrsETH: {
        address: "0xd4169E045bcF9a86cC00101225d9ED61D2F51af2",
        abi: tokenWrapperAbi as Abi,
        symbol: "wrsETH",
        decimals: 18,
      },
    },
  },
  // [xLayer.id]: {
  //   chain: xLayer,
  //   tokens: {
  //     rsETH: {
  //       address: "0x4Ef626efE4a3A279a9DC7e7a91C1c9CaaAE8e159",
  //       abi: erc20Abi,
  //       symbol: "rsETH",
  //       decimals: 18,
  //     },
  //     wrsETH: {
  //       address: "0x5A71f5888EE05B36Ded9149e6D32eE93812EE5e9",
  //       abi: tokenWrapperAbi as Abi,
  //       symbol: "wrsETH",
  //       decimals: 18,
  //     },
  //   },
  // },
};
