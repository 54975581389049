import React from "react";
import { Box } from "@chakra-ui/react";

import ModalDrawer from "../ModalDrawer";

import AccountModalHeader from "./components/AccountModalHeader";
import ScannerLink from "./components/ScannerLink";
import WalletAddress from "./components/WalletAddress";

interface AccountModalProps {
  isOpen: boolean;
  scannerLink: string;
  walletAddress?: string;
  connectorId: string;
  onCancel(isDisconnected?: boolean): void;
}

const AccountModal = ({
  isOpen,
  scannerLink,
  walletAddress,
  connectorId,
  onCancel,
}: AccountModalProps) => {
  return (
    <ModalDrawer
      isOpen={isOpen}
      closeAlert={onCancel}
      header={<AccountModalHeader />}
      headerProps={{
        fontSize: "1rem",
        p: "0",
      }}
      contentProps={{
        maxW: "600px",
        p: { base: "2.5rem 1rem 1rem", md: "2.5rem 3rem" },
      }}
      bodyProps={{
        mt: "2rem",
        p: "0",
      }}
    >
      <WalletAddress
        onCancel={onCancel}
        walletAddress={walletAddress}
        connectorId={connectorId}
      />

      <Box mt="2rem">
        <ScannerLink scannerLink={scannerLink} />
      </Box>
    </ModalDrawer>
  );
};

export default AccountModal;
