import React, { useCallback } from "react";

import { Box, useColorModeValue, useToast } from "@chakra-ui/react";

import BaseImage from "@components/BaseImage";

import { copyToClipboard, trackEvent } from "../../utils";
import { COPY_ACCOUNT_ADDRESS } from "@constants/analytics";

interface CopyIconProps {
  address?: string;
  // onClick?(): void;
}

const CopyIcon = ({ address }: CopyIconProps) => {
  const toast = useToast();
  const copyIconColor = useColorModeValue("primary", "textPrimary");

  const handleAddressCopy = useCallback(() => {
    trackEvent(COPY_ACCOUNT_ADDRESS);
    if (!address) return;
    copyToClipboard(address);
    toast({
      description: "Address Copied",
      status: "success",
      duration: 2000,
      position: "top",
      isClosable: false,
    });
  }, [address, toast]);

  return (
    <div
      onClick={(e) => {
        e.stopPropagation();
        handleAddressCopy();
      }}
    >
      <Box
        w={{ base: "16px", lg: "24px" }}
        h={{ base: "16px", lg: "24px" }}
        pos="relative"
      >
        <BaseImage
          fill
          color={copyIconColor}
          src="/assets/copy.svg"
          alt="copy"
        />
      </Box>
    </div>
  );
};

export default CopyIcon;
