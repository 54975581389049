import { Avatar, AvatarProps } from "@chakra-ui/react";
import { useMemo } from "react";
import { FaRegCircle } from "react-icons/fa6";

const KELPDAO_STORAGE = "https://firebasestorage.googleapis.com/v0/b/kelpdao/o";

interface DeFiImgProps extends AvatarProps {
  img: string;
}

export const DefiImg = ({ img, ...props }: DeFiImgProps) => {
  return (
    <Avatar src={useImgSrcFromStorage(img)} icon={<FaRegCircle />} {...props} />
  );
};

export const useImgSrcFromStorage = (img: string) => {
  const src = useMemo(
    () =>
      `${KELPDAO_STORAGE}/${encodeURIComponent(`defi/${img}`)}.png?alt=media`,
    [img]
  );
  return src;
};
