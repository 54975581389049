import React from "react";
import { Box, chakra, Text } from "@chakra-ui/react";

const TermsAndCondition = () => {
  return (
    <Box bg="backgroundPrimary" borderRadius="0.5rem">
      <Text fontSize={{ base: "12px", md: "14px" }} fontWeight="400">
        By connecting a wallet, you agree to Kelp’s{" "}
        <chakra.a
          color="primary"
          fontWeight={{ base: "400", md: "600" }}
          fontSize="14px"
          href="https://docs.google.com/document/d/1eSIGFBIZhsH35fZ-EL_UqlK_8f08Vjup/edit"
          target="_blank"
          rel="nofollow"
          borderBottom="1px dashed"
          // onClick={onTermsLinkClick}
        >
          Terms of Service
        </chakra.a>
      </Text>
    </Box>
  );
};

export default TermsAndCondition;
