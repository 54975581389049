import { TAvsApiData } from "@components/AVS/types";
import axios from "axios";
import { BigNumber } from "ethers";

interface subscribeToEmailProps {
  email: string;
  list_uuids: string[];
}

const COMMON_API_ENDPOINT = process.env.NEXT_PUBLIC_COMMON_API_URL;

export const geoAPI = () => {
  return axios.get("https://universe.kelpdao.xyz/rseth/geoData");
};

export const subscribeEmail = async (data: subscribeToEmailProps) => {
  return axios.post(
    "https://script.google.com/macros/s/AKfycbxBOMrpVjekduyQdbzxbwbRV9eBQHeAl3k9Er1iy5ztWggoKKKRDalD-w_CVMfsdJP-EA/exec",
    data
  );
};

export const getDashboardata = (address: string) => {
  return axios.get(`${COMMON_API_ENDPOINT}/km-el-points/user/${address}`);
};

export const getRewardsPointdata = (address: string) => {
  return axios.get(`${COMMON_API_ENDPOINT}/gain/user/${address}`);
};

const UNIVERSE_API_ENDPOINT = process.env.NEXT_PUBLIC_UNIVERSE_API_URL;

export const getExchangeRate = (selectedERC20: string) => {
  return axios.get(
    `${UNIVERSE_API_ENDPOINT}/rseth/exchangeRate/?lrtToken=${selectedERC20}`
  );
};

export interface TVLResponse {
  value: number;
  usdTvl: number;
}

export type TAvsResponse = {
  avs_items: Record<string, TAvsApiData>;
  nodes_data: Record<string, TAvsApiData>;
};

export const getTVL = () => {
  return axios.get<TVLResponse>(`${UNIVERSE_API_ENDPOINT}/rseth/tvl/?lrtToken`);
};
export const getAVSData = () => {
  return axios.get<TAvsResponse>(`${UNIVERSE_API_ENDPOINT}/rseth/avsData`);
};

export const getPoolLimit = (selectedERC20: string) => {
  return axios.get(
    `${UNIVERSE_API_ENDPOINT}/rseth/maxDepositLimit/?lrtToken=${selectedERC20}`
  );
};

export const getPoolStakedValue = (selectedERC20: string) => {
  return axios.get(
    `${UNIVERSE_API_ENDPOINT}/rseth/totalStaked/?lrtToken=${selectedERC20}`
  );
};

export interface KMELPointsResponse {
  time: string;
  value: {
    elPoints: string;
  };
}

export const getKelpStats = (address: `0x${string}`) => {
  return axios.get<KMELPointsResponse>(
    `${COMMON_API_ENDPOINT}/km-el-points/user/${address}`
  );
};

export interface TotalKMELPointsResponse {
  time: string;
  value: {
    latestProcessedSfrxEthDepositsBlockNumber: number;
    createdAt: number;
    latestDayBlockNumber: number;
    deposits: string;
    points: {
      eth: string;
      eigenLayerPoints: string;
      kelpMiles: string;
    };
    sk: string;
    latestProcessedStEthSharesBlockNumber: number;
    latestProccessedPointsBlockNumber: number;
    latestProcessedEthxDepositsBlockNumber: number;
    sfrxEthDeposits: string;
    pk: string;
    shares: string;
  };
}

export const getTotalKMELPoints = () => {
  return axios.get<TotalKMELPointsResponse>(
    `${COMMON_API_ENDPOINT}/km-el-points-debug/total`
  );
};

export interface ElMerkleProofResponse {
  data: {
    proof: `0x${string}`[];
    el: string;
    createdAt: string;
    sk: `0x${string}`;
    pk: string;
  } | null;
  time: string;
}
export const getElMerkleProof = (address: `0x${string}`, index: BigNumber) => {
  return axios.get<ElMerkleProofResponse>(
    `${COMMON_API_ENDPOINT}/el-merkle/proofs/index/${index.toString()}/${address}`
  );
};

export interface ElMerkleRootResponse {
  data: {
    root: string;
    km: string;
    el: string;
    createdAt: string;
    sk: string;
    pk: string;
    totalUsers: number;
    totalElPoints: string;
  };
  time: string;
}
export const getElMerkleRoot = () => {
  return axios.get<ElMerkleRootResponse>(
    `${COMMON_API_ENDPOINT}/el-merkle/root`
  );
};

export const getRestakeApy = () => {
  return axios.get<{ value: number }>(`${UNIVERSE_API_ENDPOINT}/rseth/apy`);
};
