import { useAccount } from "wagmi";
import useDappChain from "./useDappChain";
import {
  contractConfig,
  ledgerSupportedChains,
  swappingContractsConfig,
  vaultsContractConfig,
} from "@config/contractsConfig";
import useLedgerLive from "./useLedgerLive";

const useDappConfig = () => {
  const { chain } = useAccount();
  const { unsupported } = useDappChain();

  const { isLedgerConnected } = useLedgerLive();

  const ledgerSupportedChainIds = ledgerSupportedChains.map((ch) => ch.id);

  const restakeEnabledChains = Object.keys(contractConfig)
    .filter((chainId) =>
      isLedgerConnected
        ? !!ledgerSupportedChainIds.find((ch) => ch === +chainId)
        : true
    )
    .map((chainId) => contractConfig[+chainId].chain);

  const withdrawEnabledChains = Object.keys(contractConfig)
    .filter((chainId) =>
      isLedgerConnected
        ? !!ledgerSupportedChainIds.find((ch) => ch === +chainId)
        : true
    )
    .filter(
      (chaindId) =>
        !!contractConfig[+chaindId].interactionPools.lrtWithdrawalManager
    )
    .map((chainId) => contractConfig[+chainId].chain);

  const claimEnabledChains = Object.keys(contractConfig)
    .filter((chainId) =>
      isLedgerConnected
        ? !!ledgerSupportedChainIds.find((ch) => ch === +chainId)
        : true
    )
    .filter((chaindId) => !!contractConfig[+chaindId].interactionPools.kep)
    .map((chainId) => contractConfig[+chainId].chain);

  const swapEnabledChains = Object.keys(swappingContractsConfig)
    .filter((chainId) =>
      isLedgerConnected
        ? !!ledgerSupportedChainIds.find((ch) => ch === +chainId)
        : true
    )
    .map((chainId) => swappingContractsConfig[+chainId].chain);

  const isRestakeSupported =
    !unsupported && !!restakeEnabledChains.find((ch) => ch.id === chain?.id);
  const isSwapSupported =
    !unsupported && !!swapEnabledChains.find((ch) => ch.id === chain?.id);
  const isClaimSupported =
    !unsupported && !!claimEnabledChains.find((ch) => ch.id === chain?.id);
  const isWithdrawSupported =
    !unsupported && !!withdrawEnabledChains.find((ch) => ch.id === chain?.id);

  const config = isRestakeSupported
    ? contractConfig[chain!.id]
    : contractConfig[restakeEnabledChains[0]?.id];

  const swapConfig = isSwapSupported
    ? swappingContractsConfig[chain!.id]
    : swappingContractsConfig[swapEnabledChains[0]?.id];

  return {
    config,
    swapConfig,
    isRestakeSupported,
    isSwapSupported,
    isClaimSupported,
    isWithdrawSupported,
    restakeEnabledChains,
    claimEnabledChains,
    swapEnabledChains,
    withdrawEnabledChains,
    vaultsContractConfig,
  };
};

export default useDappConfig;
