import React from "react";

import { Text, Box, Spinner, Center } from "@chakra-ui/react";

import FooterButtons from "./components/FooterButtons";
import BaseImage from "@components/BaseImage";

import { TransactionType } from "../../constants/types";
import { TransactionIconMapping } from "./utils";

interface SuccessErrorModalBodyTemplateProps {
  transactionType?: TransactionType;
  isSpinnerRequired?: boolean;
  modalTitle: string;
  modalSubTitle?: string;
  modalExtraText?: string;
  primaryBtnTxt?: string; // view transactions
  secondaryBtnTxt?: string; // close or cancel
  onSubmitPrimary?: () => void;
  onSubmitSecondary?: () => void;
}

const SuccessErrorModalBodyTemplate = ({
  isSpinnerRequired = false,
  transactionType,
  modalTitle,
  modalSubTitle,
  modalExtraText,
  primaryBtnTxt,
  secondaryBtnTxt,
  onSubmitPrimary,
  onSubmitSecondary,
}: SuccessErrorModalBodyTemplateProps) => {
  // const defaultOptions = {
  //   name: "animantion",
  //   loop: true,
  //   autoplay: true,
  //   renderer: "svg",
  //   animationData: loader_dark,
  // };

  return (
    <Center textAlign="center" flexDir="column" w="100%">
      {isSpinnerRequired && (
        <Center h="160px" w="160px">
          <Spinner
            size="xl"
            thickness="4px"
            speed="0.75s"
            color="textPrimary"
          />
          {/* <Lottie options={defaultOptions} /> */}
        </Center>
      )}
      {transactionType && (
        <BaseImage
          src={TransactionIconMapping[transactionType].icon}
          width={48}
          height={48}
          alt="icon"
        />
      )}
      <Text
        mt={isSpinnerRequired ? "1rem" : "2rem"}
        fontSize={{
          base: "20px",
          md: "24px",
        }}
        lineHeight="28px"
      >
        {modalTitle}
      </Text>
      {modalSubTitle && (
        <Text
          mt="0.5rem"
          fontSize={{
            base: "12px",
            md: "14px",
          }}
          fontWeight="400"
          textAlign="center"
          color="textSecondary"
        >
          {modalSubTitle}
        </Text>
      )}
      {modalExtraText && (
        <Text
          mt="4px"
          fontSize={{
            base: "12px",
            md: "14px",
          }}
          fontWeight="400"
          textAlign="center"
          color="textSecondary"
        >
          {modalExtraText}
        </Text>
      )}
      {primaryBtnTxt || secondaryBtnTxt ? (
        <Box mt="2rem">
          <FooterButtons
            secondaryBtnTxt={secondaryBtnTxt}
            onSubmitSecondary={onSubmitSecondary}
            primaryBtnTxt={primaryBtnTxt}
            onSubmitPrimary={onSubmitPrimary}
          />
        </Box>
      ) : null}
    </Center>
  );
};

export default SuccessErrorModalBodyTemplate;
