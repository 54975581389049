import React, { useMemo } from "react";

import { Flex, Box, Text } from "@chakra-ui/react";

import CopyIcon from "../../../CopyIcon";
import { shortenAddress, trackEvent } from "../../../../utils";
import { walletIconMapping } from "../../../../constants/common";
import { DISCONNECT_WALLET } from "@constants/analytics";
import { useAccount } from "wagmi";
import useLedgerLive from "@hooks/useLedgerLive";
import BaseImage from "@components/BaseImage";

interface WalletAddressProps {
  walletAddress?: string;
  connectorId: string;
  onCancel(isDisconnected?: boolean): void;
}

const WalletAddress = ({
  walletAddress,
  connectorId,
  onCancel,
}: WalletAddressProps) => {
  const handleDisconnect = () => {
    onCancel(true);
    trackEvent(DISCONNECT_WALLET);
  };

  const { connector } = useAccount();
  const { isLedgerConnected } = useLedgerLive();

  const shortenWalletAddress = useMemo(() => {
    return shortenAddress(walletAddress || "");
  }, [walletAddress]);

  const walletIcon =
    connector && connector.icon
      ? connector.icon
      : walletIconMapping["injected"];

  return (
    <Flex
      justifyContent="space-between"
      p={{
        base: "1rem",
        md: "1.5rem 2rem",
      }}
      bg="backgroundCanvas"
      borderRadius="0.5rem"
      alignItems="center"
      width="100%"
    >
      <Flex alignItems="center">
        {connectorId && (
          <BaseImage src={walletIcon} alt="wallet" width={40} height={40} />
        )}
        <Text ml="1rem" fontWeight="600" fontSize="16px" color="textPrimary">
          {shortenWalletAddress}
        </Text>
        <Box as="button" h="24px" w="24px" cursor="pointer" ml="1rem">
          <CopyIcon address={walletAddress} />
        </Box>
      </Flex>

      {!isLedgerConnected && (
        <Box
          as="a"
          onClick={handleDisconnect}
          fontWeight={{
            base: "700",
            md: "600",
          }}
          fontSize={{
            base: "10px",
            md: "14px",
          }}
          color="textPrimary"
          cursor="pointer"
        >
          Disconnect
        </Box>
      )}
    </Flex>
  );
};

export default WalletAddress;
