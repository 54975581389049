import { ETHX } from "@config/contractsConfig";
import { createSlice } from "@reduxjs/toolkit";

export interface UserStateProps {
  tokenAmount: number;
  tokenXAmount: number;
  tokenSymbol: string;
  selectedERC20: string;
  referralId: string;
  selectedERC20Vault: string;
}

const defaultSelected = ETHX;

export const initialUserState: UserStateProps = {
  tokenAmount: 0,
  tokenXAmount: 0,
  tokenSymbol: "",
  referralId: "",
  selectedERC20: defaultSelected,
  selectedERC20Vault: "rsETH",
};

export const userSlice = createSlice({
  name: "user",
  initialState: initialUserState,
  reducers: {
    updateUserData: (state: any, action: any) => {
      for (const [key, value] of Object.entries(action.payload)) {
        state[key] = value;
      }
    },
    clearUserData: (state: any) => {
      for (const [key, value] of Object.entries(initialUserState)) {
        if (key !== "selectedERC20") {
          state[key] = value;
        }
      }
    },
  },
});

export const { updateUserData, clearUserData }: any = userSlice.actions;

export default userSlice.reducer;
