import { mainnet } from "viem/chains";
import { useAccount, useChains } from "wagmi";

const useDappChain = () => {
  const { chain, isConnected } = useAccount();
  const chains = useChains();

  const unsupported = isConnected && !chain;

  const validChain = isConnected && chain ? chain : chains[0];

  return {
    chain: validChain,
    unsupported,
    isEthereumChain: validChain.id === mainnet.id,
  };
};

export default useDappChain;
