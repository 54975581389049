import {
  Center,
  Spinner,
  Flex,
  Link,
  Text,
  Box,
  AvatarGroup,
} from "@chakra-ui/react";
import BaseImage from "@components/BaseImage";
import { DefiImg } from "@components/StorageImage";
import { POST_DEPOSIT_DEFI_EXPLORE } from "@constants/analytics";
import useDefiData from "@hooks/useDefiData";
import { useMemo } from "react";
import { TransactionType } from "../../constants/types";
import FooterButtons from "./components/FooterButtons";
import { TransactionIconMapping } from "./utils";
import { trackEvent } from "utils";

interface SuccessErrorModalBodyTemplateProps {
  transactionType?: TransactionType;
  isSpinnerRequired?: boolean;
  modalTitle: string;
  modalSubTitle?: string;
  primaryBtnTxt?: string; // view transactions
  secondaryBtnTxt?: string; // close or cancel
  onSubmitPrimary?: () => void;
  onSubmitSecondary?: () => void;
  onTxView?: () => void;
}

const StakeSuccess = ({
  isSpinnerRequired = false,
  transactionType,
  modalTitle,
  modalSubTitle,
  primaryBtnTxt,
  secondaryBtnTxt,
  onTxView,
  onSubmitPrimary,
  onSubmitSecondary,
}: SuccessErrorModalBodyTemplateProps) => {
  const defiData = useDefiData();
  const tags = useMemo(() => {
    return defiData?.Tags?.split(",").map((tag) => tag.trim()) || [];
  }, [defiData]);

  return (
    <Center textAlign="center" flexDir="column" w="100%">
      {isSpinnerRequired && (
        <Center h="160px" w="160px">
          <Spinner
            size="xl"
            thickness="4px"
            speed="0.75s"
            color="textPrimary"
          />
        </Center>
      )}
      {transactionType && (
        <BaseImage
          src={TransactionIconMapping[transactionType].icon}
          width={48}
          height={48}
          alt="icon"
        />
      )}
      <Text
        mt={isSpinnerRequired ? "1rem" : "2rem"}
        fontSize={{
          base: "20px",
          md: "24px",
        }}
        lineHeight="28px"
      >
        {modalTitle}
      </Text>

      <Flex
        mt="0.5rem"
        fontSize="12px"
        fontWeight="400"
        textAlign="center"
        color="textSecondary"
        gap="4px"
      >
        <Text>{modalSubTitle}</Text>
        <Link
          isExternal
          borderBottom="1px dashed"
          borderColor="textSecondary"
          onClick={onTxView}
        >
          View Transaction
        </Link>
      </Flex>

      <Text
        color="textSecondary"
        mt={{ base: "32px", md: "24px" }}
        fontWeight="400"
        fontSize="14px"
        lineHeight="20px"
      >
        Maximise your rewards with exclusive DeFi opportunities!
      </Text>

      <Link
        isExternal
        href={defiData["Add Liquidity"]}
        onClick={() => {
          trackEvent(POST_DEPOSIT_DEFI_EXPLORE, {
            chain: defiData.Section,
            activity_type: defiData.Activity,
            defi_protocol: defiData.Provider,
            asset: defiData.Pair,
            cta_location: defiData.Provider,
          });
        }}
        w="100%"
        _hover={{
          textDecoration: "none",
        }}
      >
        <Flex
          p="16px"
          flexDir="row"
          borderRadius="12px"
          alignItems="flex-start"
          bg="backgroundCanvas"
          gap="4px"
          fontSize="14px"
          fontWeight="400"
          my={{ base: "24px", md: "18px" }}
          w="100%"
        >
          <Flex
            flexDir={{ base: "column", md: "row" }}
            gap={{ base: "12px", md: "4px" }}
            w="100%"
          >
            <Flex
              gap={{ base: "12px", md: "4px" }}
              flex={{ base: "none", md: 1.2 }}
            >
              <Flex
                align="flex-start"
                gap="4px"
                alignItems={"center"}
                flex={{ base: "none", md: 1.5 }}
              >
                <AvatarGroup size="sm" max={2} spacing="-0.4rem">
                  <DefiImg
                    img={defiData?.["Token 1 Icon"]}
                    height="20px"
                    width="20px"
                  />
                  <DefiImg
                    img={defiData?.["Token 2 Icon"]}
                    height="20px"
                    width="20px"
                  />
                </AvatarGroup>
                <Flex direction="column" gap={2}>
                  <Flex>{defiData?.Pair || "-"}</Flex>
                </Flex>
              </Flex>

              <Flex align="center" gap="4px">
                <DefiImg img={defiData?.Provider} height="20px" width="20px" />
                <div>{defiData?.Provider || "-"}</div>
              </Flex>
            </Flex>

            <Flex
              gap={{ base: "12px", md: "4px" }}
              alignItems="center"
              flex={{ base: "none", md: 1 }}
            >
              <Text fontWeight="600" flex={{ base: "none", md: 1 }}>{`TVL: ${
                defiData?.TVL || "-"
              }`}</Text>
              {tags && tags?.length > 0 ? (
                <Center
                  p="6px 10px"
                  bg="#215959"
                  borderRadius="6px"
                  color="white"
                  fontSize="10px"
                  fontWeight="500"
                  lineHeight="12px"
                >
                  {tags[0]}
                </Center>
              ) : null}
              {tags && tags?.length > 1 ? (
                <Center
                  p="6px 10px"
                  bg="#74A8A8 "
                  borderRadius="6px"
                  color="white"
                  fontSize="10px"
                  fontWeight="500"
                  lineHeight="12px"
                >
                  {`+${tags.length - 1}`}
                </Center>
              ) : null}
            </Flex>
          </Flex>
          <Flex maxW="12px">
            <BaseImage
              height="12"
              width="12"
              alt="link"
              src="/assets/link-arrow.svg"
            />
          </Flex>
        </Flex>
      </Link>

      {primaryBtnTxt || secondaryBtnTxt ? (
        <Box>
          <FooterButtons
            secondaryBtnTxt={secondaryBtnTxt}
            onSubmitSecondary={onSubmitSecondary}
            primaryBtnTxt={primaryBtnTxt}
            onSubmitPrimary={onSubmitPrimary}
          />
        </Box>
      ) : null}
    </Center>
  );
};

export default StakeSuccess;
