import React from "react";
import { Flex, Text } from "@chakra-ui/react";

const AccountModalHeader = () => {
  return (
    <Flex justifyContent="center" alignItems="center">
      <Text fontWeight="600" fontSize="24px" color="textPrimary">
        Wallet
      </Text>
    </Flex>
  );
};

export default AccountModalHeader;
