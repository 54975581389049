import { ReactNode } from "react";
import { createConfig, fallback, http, WagmiProvider } from "wagmi";
import {
  connectorsForWallets,
  lightTheme,
  RainbowKitProvider,
} from "@rainbow-me/rainbowkit";
import {
  arbitrum,
  base,
  linea,
  blast,
  Chain,
  mainnet,
  manta,
  mode,
  optimism,
  scroll,
  zkSync,
  xLayer,
} from "viem/chains";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import {
  okxWallet,
  coinbaseWallet,
  metaMaskWallet,
  rabbyWallet,
  walletConnectWallet,
  bitgetWallet,
  imTokenWallet,
  ledgerWallet,
  safepalWallet,
  tokenPocketWallet,
  trustWallet,
} from "@rainbow-me/rainbowkit/wallets";
import SDKProvider from "./SDKProvider";
import TermsAndCondition from "@components/WalletConnectModal/components/TermsAndCondition";

interface WagmiProviersProps {
  children: ReactNode;
}

const ALCHEMY_ETHEREUM_KEY = "iuphmpG3ftqu_yNyvpDsFtukkzZbyqDK";
const ALCHEMY_ARBITRUM_KEY = "sJHV-fI60BWPJvFtqT-Dbnazdz6cKHTa";

const WC_PROJECT_ID = process.env.NEXT_PUBLIC_WC_PROJECT_ID as string;
const queryClient = new QueryClient();

const connectors = connectorsForWallets(
  [
    {
      groupName: "Recommended",
      wallets: [
        metaMaskWallet,
        coinbaseWallet,
        walletConnectWallet,
        okxWallet,
        trustWallet,
        rabbyWallet,
        safepalWallet,
        tokenPocketWallet,
        bitgetWallet,
        imTokenWallet,
        ledgerWallet,
      ],
    },
  ],
  {
    appName: "Kelp DAO",
    projectId: WC_PROJECT_ID,
  }
);

type IconExtra = {
  iconBackground: string;
  iconUrl: string;
};

type ChainWithIcons = Chain & IconExtra;

export const supportedChains: [ChainWithIcons, ...ChainWithIcons[]] = [
  {
    ...mainnet,
    iconBackground: "#fff",
    iconUrl: "/assets/chains/ethereum.svg",
  },
  {
    ...arbitrum,
    iconBackground: "#000",
    iconUrl: "/assets/chains/arbitrum.svg",
  },
  {
    ...manta,
    iconBackground: "#000",
    iconUrl: "/assets/chains/manta-pacific.svg",
  },
  {
    ...mode,
    iconBackground: "#000",
    iconUrl: "/assets/chains/mode.svg",
  },
  {
    ...blast,
    iconBackground: "#000",
    iconUrl: "/assets/chains/blast.png",
  },
  {
    ...scroll,
    iconBackground: "#000",
    iconUrl: "/assets/chains/scroll.svg",
  },
  {
    ...optimism,
    iconBackground: "#000",
    iconUrl: "/assets/chains/optimism.svg",
  },
  {
    ...base,
    iconBackground: "#000",
    iconUrl: "/assets/chains/base.svg",
  },
  {
    ...linea,
    iconBackground: "#000",
    iconUrl: "/assets/chains/linea.svg",
  },
  {
    ...zkSync,
    iconBackground: "#000",
    iconUrl: "/assets/chains/zksync.svg",
  },
  {
    ...xLayer,
    iconBackground: "#000",
    iconUrl: "/assets/chains/xlayer.svg",
  },
];

export const wagmiConfig = createConfig({
  chains: supportedChains,
  ssr: true,
  transports: {
    [mainnet.id]: fallback([
      http(`https://eth-mainnet.g.alchemy.com/v2/${ALCHEMY_ETHEREUM_KEY}`),
      http("https://rpc.ankr.com/eth"),
    ]),
    [arbitrum.id]: fallback([
      http(`https://arb-mainnet.g.alchemy.com/v2/${ALCHEMY_ARBITRUM_KEY}`),
      http("https://rpc.ankr.com/arbitrum"),
    ]),
    [manta.id]: fallback([
      http("https://pacific-rpc.manta.network/http"),
      http("https://manta-pacific.drpc.org"),
    ]),
    [mode.id]: fallback([
      http("https://mainnet.mode.network/"),
      http("https://1rpc.io/mode"),
    ]),
    [blast.id]: fallback([
      http("https://rpc.blast.io"),
      http("https://rpc.ankr.com/blast"),
    ]),
    [scroll.id]: fallback([
      http("https://rpc.scroll.io"),
      http("https://1rpc.io/scroll"),
    ]),
    [optimism.id]: fallback([
      http("https://mainnet.optimism.io"),
      http("https://rpc.ankr.com/optimism"),
    ]),
    [base.id]: fallback([
      http("https://mainnet.base.org"),
      http("https://base-rpc.publicnode.com"),
    ]),
    [linea.id]: fallback([
      http("https://linea-mainnet.public.blastapi.io"), // TODO update
      http("https://rpc.linea.build"), // TODO update
    ]),
    [zkSync.id]: fallback([
      http("https://1rpc.io/zksync2-era"), // TODO update
      http("https://mainnet.era.zksync.io"), // TODO update
    ]),
    [xLayer.id]: fallback([
      http("https://rpc.xlayer.tech"), // TODO update
      http("https://xlayerrpc.okx.com"), // TODO update
    ]),
  },
  connectors,
});

const WagmiProvier = ({ children }: WagmiProviersProps) => {
  const defaultTheme = lightTheme({
    accentColor: "#3F6870",
    fontStack: "system",
    overlayBlur: "small",
  });

  return (
    <>
      <WagmiProvider config={wagmiConfig}>
        <QueryClientProvider client={queryClient}>
          <RainbowKitProvider
            appInfo={{
              appName: "Kelp Dao",
              disclaimer: TermsAndCondition,
            }}
            theme={{
              ...defaultTheme,
              colors: {
                ...defaultTheme.colors,
                modalBackground: "#E4F8F8",
                modalText: "#3F6870",
              },
            }}
          >
            <SDKProvider>{children}</SDKProvider>
          </RainbowKitProvider>
        </QueryClientProvider>
      </WagmiProvider>
    </>
  );
};

export default WagmiProvier;
