import { Flex, Link, Text } from "@chakra-ui/react";

import { BANNER_DATA } from "@constants/banner";

const Banner = () => {
  return (
    <>
      {BANNER_DATA.text ? (
        <Flex
          pos={"relative"}
          zIndex={2}
          bg="#ECBB85"
          fontSize={{ base: "12px", md: "14px" }}
          color="textPrimary"
          fontWeight="500"
          lineHeight="140%"
          alignItems="center"
          justifyContent="center"
          gap="16px"
          p="8px"
        >
          <Text>{BANNER_DATA.text}</Text>
          {BANNER_DATA.cta_text && BANNER_DATA.cta_link ? (
            <Link
              isExternal
              href={BANNER_DATA.cta_link}
              minW="75px"
              _hover={{
                textDecoration: "none",
              }}
            >
              <Text
                fontSize="12px"
                fontWeight="600"
                lineHeight="20px"
                textTransform="uppercase"
                borderBottom="1px dashed"
                borderColor="textPrimary"
              >
                {BANNER_DATA.cta_text}
              </Text>
            </Link>
          ) : null}
        </Flex>
      ) : null}
    </>
  );
};

export default Banner;
