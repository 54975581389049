import { getExchangeRate } from "services";
import { useCallback, useEffect, useState } from "react";
import { useInterval, useIdle } from "react-use";
import { useSelector } from "react-redux";
import {
  ETH,
  ETHX,
  SFRXETH,
  STETH,
  WETH,
  WSTETH,
} from "@config/contractsConfig";

export type ERDataType = {
  exchangeRate: number | undefined;
  isLoading: boolean;
};

const POLLING_INTERVAL = 10000;

const APPOVED_ERC20 = [ETHX, ETH, STETH, WETH, WSTETH, SFRXETH];

const useExchangeRate = () => {
  const isIdle = useIdle(150e3);

  const { selectedERC20 } = useSelector((state: any) => state.user);

  const [data, setData] = useState<ERDataType>({
    exchangeRate: undefined,
    isLoading: true,
  });

  const fetchExchangeRate = useCallback(async () => {
    if (APPOVED_ERC20.includes(selectedERC20)) {
      const response = await getExchangeRate(selectedERC20);

      setData({
        exchangeRate: response?.data?.value as number,
        isLoading: false,
      });
    }
  }, [selectedERC20]);

  useEffect(() => {
    fetchExchangeRate();
  }, [fetchExchangeRate]);

  useInterval(() => {
    if (!isIdle) {
      fetchExchangeRate();
    }
  }, POLLING_INTERVAL);

  return data;
};

export default useExchangeRate;
