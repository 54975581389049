import { Flex, Divider, Text } from "@chakra-ui/react";
import { STEPS_TYPE } from "@constants/common";
import { useCallback } from "react";

import Image from "next/image";

interface StepperProps {
  activeIndex: number;
  steppersData: Array<STEPS_TYPE>;
}

const PROGRESS_BORDER_COLOR = "#2196F3";
const COMPLETED_BORDER_COLOR = "#07A658";
const DEFAULT_BORDER_COLOR = "textTertiary";
const TXN_STATE_ICON_PREFIX = "/assets/wallet_txns";

const Stepper = ({ activeIndex, steppersData }: StepperProps) => {
  const isCompleted = (index: number) => index < activeIndex;
  const inProgess = (index: number) => index === activeIndex;

  const getLeftBorderColor = useCallback(
    (index: number) => {
      if (inProgess(index)) {
        return PROGRESS_BORDER_COLOR;
      } else if (isCompleted(index)) {
        return COMPLETED_BORDER_COLOR;
      }
      return DEFAULT_BORDER_COLOR;
    },
    [activeIndex]
  );

  return (
    <Flex w="100%" alignItems="flex-start" gap="0px">
      {steppersData.map((data, index: number) => (
        <Flex
          flexDir="column"
          gap="8px"
          flex="1 1 0%"
          justifyContent="center"
          key={data.text}
        >
          <Flex alignItems="center">
            <Divider
              borderWidth={
                isCompleted(index) || inProgess(index) ? "4px" : "2px"
              }
              borderStyle="solid"
              borderColor={getLeftBorderColor(index)}
              visibility={index === 0 ? "hidden" : "inherit"}
            />

            <Image
              height={28}
              width={28}
              alt="txn"
              src={
                isCompleted(index)
                  ? `${TXN_STATE_ICON_PREFIX}/completed.svg`
                  : inProgess(index)
                  ? `${TXN_STATE_ICON_PREFIX}/progress.svg`
                  : `${TXN_STATE_ICON_PREFIX}/default.svg`
              }
            />
            {/* </Box> */}

            <Divider
              borderWidth={isCompleted(index) ? "4px" : "2px"}
              borderStyle="solid"
              borderColor={
                isCompleted(index)
                  ? index + 1 < activeIndex
                    ? COMPLETED_BORDER_COLOR
                    : PROGRESS_BORDER_COLOR
                  : DEFAULT_BORDER_COLOR
              }
              visibility={
                index === steppersData.length - 1 ? "hidden" : "inherit"
              }
            />
          </Flex>
          <Flex flexDir="column" alignItems="center">
            <Text
              fontSize="16px"
              lineHeight="24px"
              fontWeight="600"
              textAlign="center"
              color={
                isCompleted(index) || inProgess(index) ? "#142528" : "#A1B6BA"
              }
            >
              {data.text}
            </Text>

            {data.pair_from && data.pair_to ? (
              <Flex
                alignItems="center"
                fontSize="14px"
                lineHeight="20px"
                fontWeight="500"
                color={isCompleted(index) ? "#3F6870" : "#A1B6BA"}
              >
                <Text>{data.pair_from}</Text>
                <Image
                  src="/assets/arrow.svg"
                  height={16}
                  width={16}
                  alt="arrow"
                />
                <Text>{data.pair_to}</Text>
              </Flex>
            ) : null}
          </Flex>
        </Flex>
      ))}
    </Flex>
  );
};

export default Stepper;
