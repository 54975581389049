import { extendTheme } from "@chakra-ui/react";
// import { commonStyles } from "./common";

import type { ComponentStyleConfig } from "@chakra-ui/theme";

const Tabs: ComponentStyleConfig = {
  variants: {
    primary: {
      tablist: {
        borderBottom: "1px solid",
        borderColor: "backgroundDivider",
        // w: {
        //   md: "fit-content",
        //   base: "100%",
        // },
      },
      tab: {
        p: "12px 24px",
        color: "textTertiary",
        fontWeight: "600",
        fontSize: "16px",
        lineHeight: "24px",
        _selected: {
          color: "textPrimary",
          borderBottom: "2px solid",
          borderColor: "textPrimary",
          mt: "1px",
          borderRadius: "16px 16px 0px 0px",
        },
      },
      tabPanel: {
        p: 0,
      },
    },
  },
  defaultProps: {
    variant: "primary",
  },
};

const commonStyles = {
  components: {
    Tabs,
    // Button: {
    //   variants: {
    //     primary: {
    //       _hover: {
    //         border: "inherit",
    //         bg: "none",
    //         boxShadow: "inherit",
    //         // opacity: 1,
    //       },
    //     },
    //   },
    //   defaultProps: {
    //     variant: "primary",
    //   },
    // },
  },
};

export const styles = {
  global: () => ({
    "html, body": {
      padding: 0,
      margin: 0,
      //   fontFamily: "Gilroy, Helvetica, sans-serif, system-ui",
    },
    body: {
      bg: "backgroundCanvas",
      color: "textPrimary",
    },
  }),
};

export const themeColors = {
  theme: {
    50: "#E6FFFA",
    100: "#B2F5EA",
    200: "#2FA8AF",
    300: "#4FD1C5",
    400: "#38B2AC",
    500: "#2FA8AF",
    600: "#2C7A7B",
    700: "#285E61",
    800: "#234E52",
    900: "#1D4044",
  },
};

export const semanticTokens = {
  colors: {
    backgroundCanvas: {
      default: "#D8F0F0",
    },
    backgroundCard: {
      default: "#FFFFFF",
    },
    backgroundGreen: {
      default: "#074747",
    },
    textEigen: {
      default: "#1A0C6D",
    },
    textSecondary: {
      default: "#3F6870",
    },
    textTableFonts: {
      default: "#047C80",
    },
    textPrimary: {
      default: "#075A5A",
    },
    textTertiary: {
      default: "#A1B6BA",
    },
    backgroundDivider: {
      default: "#B6DFDF",
    },
    backgroundField: {
      default: "#E4F8F8",
    },
    accent1: {
      default: "#6E9BDE",
    },
    error: {
      default: "#FF4D4D",
    },
    success: {
      default: "#38A169",
    },
    warning: {
      default: "#ECC94B",
    },
    modalOverlay: {
      default: "#075a5acc",
    },
  },
};

export const getCustomTheme = () => {
  return extendTheme({
    colors: themeColors,
    // fonts: {
    //   body: `Poppins, Montserrat, -apple-system, BlinkMacSystemFont, Roboto, sans-serif;`,
    // },
    styles,
    semanticTokens: semanticTokens,
    ...commonStyles,
  });
};
