export const EVENTS = {
  HANDLE_CONNECT: "handleConnect",
  HANDLE_DISCONNECT: "handleDisconnect",
  HANDLE_STAKE: "handleStake",
  HANDLE_UNSTAKE: "handleUnstake",
  HANDLE_CLAIM: "handleClaim",
  HANDLE_ERC20_APPROVE: "handleTokenApprove",
  HANDLE_XTOKEN_APPROVE: "handleXTokenApprove",
  OPEN_WALLET_MODAL: "openWalletModal",
  SWITCH_NETWORK: "switchnetwork",
  HANDLE_VAULT_DEPOSIT: "handleVaultDeposit",
  HANDLE_VAULT_WITHDRAW: "handleVaultWithdraw",
  HANDLE_VAULT_APPROVE: "handleVaultApprove",
  HANDLE_VAULT_XTOKEN_APPROVE: "handleVaultXTokenApprove",
  HANDLE_VAULT_CLAIM: "handleVaultClaim",
};
