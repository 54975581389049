import Script from "next/script";

const SUB_ROUTE = process.env.NEXT_PUBLIC_SUB_ROUTE;

const RudderStackScript = ({ fileName }: { fileName: string }) => {
  const basePath = SUB_ROUTE ? SUB_ROUTE + "/" : "/";
  const host = "https://universe-stage.kelpdao.xyz";
  const rsMinJs = `${basePath}analytics/${fileName}`;
  const dataPlane = host + "/l-p/rs-dataplane";
  const configUrl = host + "/rudderlabs/sourceConfig";

  const writeKey = "2icynXcfd3WmBiywUW6WymDCIco";

  return (
    <Script
      id="rudderstackScriptInject"
      type="text/javascript"
      async
      dangerouslySetInnerHTML={{
        __html: `!function(){var e=window.rudderanalytics=window.rudderanalytics||[];e.methods=["load","page","track","identify","alias","group","ready","reset","getAnonymousId","setAnonymousId","getUserId","getUserTraits","getGroupId","getGroupTraits"],e.factory=function(t){return function(){var r=Array.prototype.slice.call(arguments);return r.unshift(t),e.push(r),e}};for(var t=0;t<e.methods.length;t++){var r=e.methods[t];e[r]=e.factory(r)}e.loadJS=function(e,t){var r=document.createElement("script");r.type="text/javascript",r.async=!0,r.src="${rsMinJs}";var a=document.getElementsByTagName("script")[0];a.parentNode.insertBefore(r,a)},e.loadJS(),
          e.load("${writeKey}","${dataPlane}", {configUrl: "${configUrl}"}),
          e.page()}();`,
      }}
    />
  );
};

export default RudderStackScript;
