import { useMediaQuery } from "@chakra-ui/react";
import axios from "axios";
import { TransactionResponse } from "@ethersproject/providers";
import { ReactNode, useCallback, useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Connector,
  useAccount,
  useConnect,
  useDisconnect,
  usePublicClient,
  useSwitchChain,
  useWalletClient,
  useWriteContract,
} from "wagmi";

import { Text, useToast } from "@chakra-ui/react";
import {
  readContract,
  writeContract as writeContractAction,
  SendTransactionParameters,
  sendTransaction,
} from "@wagmi/core";

import AccountModal from "../components/AccountModal";
import WalletConnectModal from "../components/WalletConnectModal";
import {
  APPROVAL_AMOUNT,
  STADER_CLIENT_ID,
  toFixedWithoutRounding,
  TXN_STATUSES,
  TXN_TYPES,
} from "../constants/common";

import { MODAL_TYPES } from "../constants/modal";
import { EVENTS } from "../constants/reduxEvents";

import {
  CLAIM_FAILED,
  CLAIM_SUCCESSFUL,
  MAX_STAKE,
  MAX_UNSTAKE,
  STADER_RESTAKE_COMPLETE,
  STADER_RESTAKE_FAILED,
  STADER_RESTAKE_INITIATE,
  STAKE_FAILED,
  STAKE_SUCCESSFUL,
  TOKENX_APPROVE_FAILED,
  TOKENX_APPROVE_SUCCESSFUL,
  TOKEN_APPROVE_FAILED,
  TOKEN_APPROVE_SUCCESSFUL,
  UNSTAKE_FAILED,
  UNSTAKE_SUCCESSFUL,
  VIEW_ADDRESS_ON_SCANNER,
  WALLET_CONNECTED_SUCCESSFULLY,
  VAULT_CLAIM_FAILED,
  VAULT_CLAIM_SUCCESSFUL,
  VAULT_DEPOSIT_SUCCESSFUL,
  VAULT_DEPOSIT_FAILED,
  VAULT_WITHDRAW_SUCCESSFUL,
  VAULT_WITHDRAW_FAILED,
} from "@constants/analytics";
import useTokenBalance from "@hooks/useTokenBalance";
import TransactionModal from "../components/TransactionModal";
import WalletMessageModal from "../components/WalletMessageModal";
import {
  clearUserData,
  updateModalData,
  updateStakeData,
  updateWalletData,
} from "../store";
import {
  emitReduxEvent,
  formatNumber,
  isErrorRejectedByUser,
  trackEvent,
} from "../utils";
import { useConnectModal } from "@rainbow-me/rainbowkit";
import useDappConfig from "@hooks/useDappConfig";
import useDappChain from "@hooks/useDappChain";
import { ETH, ETHX, STETH } from "@config/contractsConfig";
import { parseEther, sha256, formatUnits } from "viem";
import { arbitrum, mainnet, xLayer } from "viem/chains";
import useAutoConnect from "@hooks/useAutoConnect";
import { ethers } from "ethers";
import { wagmiConfig } from "./WagmiProvider";
import StaderLedgerTxModal from "@components/StaderLedgerTxModal/StaderLedgerTxModal";
// import useUnstakeInfo from "@hooks/useUnstakeInfo";
// import useBufferPool from "@hooks/useBufferPool";

export interface TxnErrorType {
  hasUserDenied: boolean;
  promptError: string;
}

export interface TxnType {
  original: any;
  hash: string;
  status: string;
  amount: string | null;
  token: string;
  type: string;
}

const initialTxnError = {
  hasUserDenied: false,
  promptError: "",
};

interface StaderLedgerTxnType {
  hash: string;
  status: string;
  activeIndex: number;
  error?: string;
  hasUserDenied?: boolean;
}

const initialStaderLedgerTxn = {
  hash: "",
  status: "",
  activeIndex: -1,
};

const initialTxn = {
  original: undefined,
  hash: "",
  status: "",
  amount: null,
  token: "",
  type: "",
};

interface ServicesProps {
  children: ReactNode;
}

const Services = ({ children }: ServicesProps) => {
  const transactionWaitRef = useRef(false);

  const { modalType, isMobileView, errorMessage } = useSelector(
    (state: any) => state.modal
  );
  const { emitter } = useSelector((state: any) => state.event);
  const { selectedERC20, selectedERC20Vault } = useSelector(
    (state: any) => state.user
  );
  const { nativeTokenBalance, tokensBalances } = useTokenBalance();

  const tokenAmount =
    selectedERC20 === ETH
      ? nativeTokenBalance
      : tokensBalances[selectedERC20] || 0;

  useAutoConnect();

  const [txnError, setTxnError] = useState<TxnErrorType>(initialTxnError);
  const [txnLoader, setTxnLoader] = useState(false);
  const [txnDetail, setTxnDetail] = useState<TxnType>(initialTxn);
  const [staderLedgerTxn, setStaderLedgerTxn] = useState<StaderLedgerTxnType>(
    initialStaderLedgerTxn
  );

  const [isMobileOrTablet] = useMediaQuery("(max-width: 768px)");

  const toast = useToast();
  const { chain: wagmiChain } = useAccount();

  const { data: signer } = useWalletClient();
  const { switchChain, chains } = useSwitchChain();
  const { isConnected, address, connector: activeConnector } = useAccount();
  const { chain, isEthereumChain } = useDappChain();
  const publicClient = usePublicClient();
  const {
    config,
    isRestakeSupported,
    isWithdrawSupported,
    vaultsContractConfig,
  } = useDappConfig();

  const { writeContract } = useWriteContract();

  useTokenBalance();

  const dispatch = useDispatch();

  const { disconnect } = useDisconnect();

  const { connectModalOpen } = useConnectModal();

  useEffect(() => {
    if (connectModalOpen) {
      setTimeout(() => {
        dispatch(
          updateModalData({
            modalType: "",
            isMobileView: false,
            errorMessage: "",
          })
        );
      }, 200);
    }
  }, [connectModalOpen, dispatch]);

  const handleCloseModal = useCallback(() => {
    dispatch(
      updateModalData({
        modalType: "",
        isMobileView: false,
        errorMessage: "",
      })
    );
  }, [dispatch]);

  const { connect } = useConnect();

  const onWalletConnect = useCallback(
    (connector: Connector) => {
      connect(
        { connector },
        {
          onError(error) {
            dispatch(
              updateModalData({
                modalType: MODAL_TYPES.WALLET_CONNECT_MESSAGE_MODAL,
                isMobileView: false,
                errorMessage: error.message,
              })
            );
          },
          onSuccess() {
            trackEvent(WALLET_CONNECTED_SUCCESSFULLY);
            dispatch(
              updateModalData({
                modalType: MODAL_TYPES.WALLET_CONNECT_MESSAGE_MODAL,
                isMobileView: false,
              })
            );
          },
        }
      );
    },
    [connect]
  );

  const onCloseAccountModal = useCallback(
    (isWalletDisconnect: boolean) => {
      handleCloseModal();
      if (isWalletDisconnect) {
        disconnect();
        dispatch(clearUserData());
      }
    },
    [disconnect, handleCloseModal, dispatch]
  );

  const initiateNewTxn = useCallback(() => {
    transactionWaitRef.current = false;
    setTxnError(initialTxnError);
    setTxnLoader(true);
    setTxnDetail(initialTxn);
  }, []);

  const updateTransactionDetails = useCallback(
    (
      txn: TransactionResponse,
      type: string,
      amount: string | null,
      token: string
      // id: number | null = null
    ) => {
      setTxnDetail({
        original: txn,
        hash: txn.hash,
        status: TXN_STATUSES.PENDING,
        amount,
        token,
        type,
        // id,
      });
    },
    []
  );

  const resetTxns = useCallback(() => {
    setTxnDetail(initialTxn);
    setTxnLoader(false);
  }, []);

  const handleErrror = useCallback(
    (error: string, hasUserDenied = false, custmError?: string) => {
      setTxnError({
        hasUserDenied: hasUserDenied,
        promptError: error,
      });
      if (!txnLoader) {
        let errorMsg = hasUserDenied ? "User rejected request" : error;
        if (custmError) {
          errorMsg = custmError;
        }
        toast({
          description: (
            <Text fontWeight={600} fontSize="16px">
              {errorMsg}
            </Text>
          ),
          status: "error",
          // icon: <Icon Icon={ErrorToast} width="24px" height="24px" />,
          duration: 5000,
          position: "top",
          isClosable: false,
        });
      }
    },
    [toast, txnLoader]
  );

  // const handleVaultXTokenApprove = () => {
  //   const amount = APPROVAL_AMOUNT;

  //   initiateNewTxn();

  //   if (address && signer) {
  //     const token = vaultsContractConfig.xToken;

  //     writeContract(
  //       {
  //         ...token,
  //         functionName: "approve",
  //         args: [
  //           vaultsContractConfig.interactionPool.address,
  //           parseEther(amount),
  //         ],
  //       },
  //       {
  //         onError: (error: any) => {
  //           handleErrror(
  //             error?.error || error?.message,
  //             isErrorRejectedByUser(error),
  //             "Approval denied"
  //           );
  //           //  trackEvent(TOKEN_APPROVE_FAILED, {
  //           //    reason: isErrorRejectedByUser(error)
  //           //      ? "user rejected"
  //           //      : error?.error || error?.message,
  //           //  });
  //           resetTxns();
  //         },
  //         onSuccess: (hash) => {
  //           const txDetails = {
  //             hash,
  //             wait: () => publicClient?.waitForTransactionReceipt({ hash }),
  //           };
  //           updateTransactionDetails(
  //             txDetails as any,
  //             TXN_TYPES.XTOKEN_APPROVE,
  //             null,
  //             token.symbol
  //           );
  //         },
  //       }
  //     );
  //   }
  // };

  const handleVaultApprove = () => {
    const amount = APPROVAL_AMOUNT;

    initiateNewTxn();
    if (address && signer) {
      let token = config.tokens[selectedERC20Vault];
      if (selectedERC20Vault === "rsETH") {
        token = {
          ...config.xtoken,
          tags: [],
        };
      }

      writeContract(
        {
          ...token,
          functionName: "approve",
          args: [
            vaultsContractConfig.rsETHAdapterPool.address,
            parseEther(amount),
          ],
        },
        {
          onError: (error: any) => {
            handleErrror(
              error?.error || error?.message,
              isErrorRejectedByUser(error),
              "Approval denied"
            );
            //  trackEvent(TOKEN_APPROVE_FAILED, {
            //    reason: isErrorRejectedByUser(error)
            //      ? "user rejected"
            //      : error?.error || error?.message,
            //  });
            resetTxns();
          },
          onSuccess: (hash) => {
            const txDetails = {
              hash,
              wait: () => publicClient?.waitForTransactionReceipt({ hash }),
            };
            updateTransactionDetails(
              txDetails as any,
              TXN_TYPES.ERC20_APPROVE,
              null,
              token.symbol
            );
          },
        }
      );
    }
  };

  const handleApproveToken = (
    forUnstake = false,
    { amount = APPROVAL_AMOUNT }
  ) => {
    initiateNewTxn();

    if (address && signer) {
      dispatch(updateStakeData({ approveTokenLoading: true }));

      const token = forUnstake ? config.xtoken : config.tokens[selectedERC20];

      writeContract(
        {
          ...token,
          functionName: "approve",
          args: [
            config.interactionPools[
              forUnstake ? "lrtWithdrawalManager" : "lrtDepositPool"
            ].address,
            parseEther(amount),
          ],
        },
        {
          onError: (error: any) => {
            handleErrror(
              error?.error || error?.message,
              isErrorRejectedByUser(error),
              "Approval denied"
            );
            trackEvent(TOKEN_APPROVE_FAILED, {
              reason: isErrorRejectedByUser(error)
                ? "user rejected"
                : error?.error || error?.message,
            });
            resetTxns();
          },
          onSuccess: (hash) => {
            const txDetails = {
              hash,
              wait: () => publicClient?.waitForTransactionReceipt({ hash }),
            };
            updateTransactionDetails(
              txDetails as any,
              forUnstake ? TXN_TYPES.XTOKEN_APPROVE : TXN_TYPES.ERC20_APPROVE,
              null,
              config.tokens[selectedERC20].symbol
            );
          },
          onSettled: () => {
            dispatch(updateStakeData({ approveTokenLoading: false }));
          },
        }
      );
    }
  };

  const isApprovalTransaction = (transactionType: string) => {
    return [TXN_TYPES.ERC20_APPROVE, TXN_TYPES.XTOKEN_APPROVE].includes(
      transactionType
    );
  };

  const updateTransactionDetailsWithResults = useCallback(
    (status: string) => {
      dispatch(
        updateStakeData({
          isTransactionProcessing: false,
        })
      );
      setTxnDetail({
        ...txnDetail,
        status,
      });
    },
    [txnDetail]
  );

  const getAssset = useCallback(
    (type: string) => {
      if (type && type.toLowerCase().includes("vault"))
        return selectedERC20Vault;
      return selectedERC20;
    },
    [selectedERC20Vault, selectedERC20]
  );

  const getStakeUnstakeFirebaseMethodName = useCallback(
    (isSuccessful: boolean, type: string) => {
      switch (type) {
        case TXN_TYPES.UNSTAKE:
          return isSuccessful ? UNSTAKE_SUCCESSFUL : UNSTAKE_FAILED;
        case TXN_TYPES.STAKE:
          return isSuccessful ? STAKE_SUCCESSFUL : STAKE_FAILED;
        case TXN_TYPES.ERC20_APPROVE:
          return isSuccessful ? TOKEN_APPROVE_SUCCESSFUL : TOKEN_APPROVE_FAILED;
        case TXN_TYPES.XTOKEN_APPROVE:
          return isSuccessful
            ? TOKENX_APPROVE_SUCCESSFUL
            : TOKENX_APPROVE_FAILED;
        case TXN_TYPES.VAULT_DEPOSIT:
          return isSuccessful ? VAULT_DEPOSIT_SUCCESSFUL : VAULT_DEPOSIT_FAILED;
        case TXN_TYPES.VAULT_WITHDRAW:
          return isSuccessful
            ? VAULT_WITHDRAW_SUCCESSFUL
            : VAULT_WITHDRAW_FAILED;
        case TXN_TYPES.VAULT_CLAIM:
          return isSuccessful ? VAULT_CLAIM_SUCCESSFUL : VAULT_CLAIM_FAILED;
        case TXN_TYPES.CLAIM:
          return isSuccessful ? CLAIM_SUCCESSFUL : CLAIM_FAILED;
      }
      return "";
    },
    []
  );

  const logStakeUnstakeEvent = useCallback(
    (isSuccessful: boolean, type: string, hash: string = "") => {
      const amount = txnDetail.amount;

      trackEvent(getStakeUnstakeFirebaseMethodName(isSuccessful, type), {
        amount: parseFloat(Number(amount).toString()),
        hash: hash,
        asset: getAssset(type),
      });
    },
    [getStakeUnstakeFirebaseMethodName, txnDetail.amount]
  );

  useEffect(() => {
    if (
      txnDetail.original &&
      txnDetail.status === TXN_STATUSES.PENDING &&
      !transactionWaitRef.current
    ) {
      transactionWaitRef.current = true;
      txnDetail.original
        .wait(1)
        .then((confirmation: any) => {
          if (confirmation) {
            const type = txnDetail.type;
            const hash = txnDetail.hash;
            if (confirmation.blockNumber && confirmation.status) {
              updateTransactionDetailsWithResults(TXN_STATUSES.SUCCESS);
              logStakeUnstakeEvent(true, type, hash);

              if (isApprovalTransaction(type)) {
                resetTxns();
                const _type =
                  TXN_TYPES.ERC20_APPROVE === type ? "restaking" : "unstaking";
                toast({
                  description: (
                    <Text fontWeight={600} fontSize="16px">
                      Asset approved, proceed to {_type}
                    </Text>
                  ),
                  status: "success",
                  // icon: <Icon Icon={SuccessCheck} width="24px" height="24px" />,
                  duration: 5000,
                  position: "top",
                  isClosable: false,
                });
              }
              if (!txnLoader && !isApprovalTransaction(type)) {
                toast({
                  description: `${type} Transaction Successful`,
                  status: "success",
                  duration: 5000,
                  position: "top",
                });
              }
            } else {
              // logStakeUnstakeEvent(false, type, hash, "");
              updateTransactionDetailsWithResults(TXN_STATUSES.ERROR);
              if (!txnLoader && !isApprovalTransaction(type)) {
                toast({
                  description: `${type} Transaction failed`,
                  status: "error",
                  duration: 5000,
                  position: "top",
                });
              }
            }
          }
        })
        .catch((error: any) => {
          dispatch(
            updateStakeData({
              isTransactionProcessing: false,
            })
          );
          setTxnError(error?.error || error?.message);
        });
    }
  }, [
    dispatch,
    resetTxns,
    setTxnError,
    toast,
    txnDetail.hash,
    txnDetail.original,
    txnDetail.status,
    txnDetail.type,
    txnLoader,
    updateTransactionDetailsWithResults,
  ]);

  const openWalletModal = () => {
    dispatch(
      updateModalData({
        modalType: MODAL_TYPES.CONNECT_WALLET,
        isMobileView: isMobileOrTablet ? true : false,
      })
    );
  };

  const handleStakeForStaderLedger = async ({ amount, referralId }: any) => {
    // Step1 = convert to ETHX
    // Step2 = fetch allowance & ETHX balance
    // Step3 = If alreaddyy approve jump to step5
    // Step4 = If not approved approve allowance
    // step5 = ETHX to rsETH

    let activeIndex = 0;
    setStaderLedgerTxn({
      ...staderLedgerTxn,
      activeIndex,
    });

    const params = { from: address, amount, referralId, lstToken: undefined };

    const request = await axios.post(
      `https://yields.kelpdao.xyz/ethx/stake?clientId=${STADER_CLIENT_ID}`,
      {
        ...params,
        chainId: wagmiChain?.id,
      }
    );

    if (!signer) {
      return;
    }

    const txString = request.data;

    const decodedTx = ethers.utils.parseTransaction(
      Buffer.from(txString, "hex")
    );

    const viemTx: SendTransactionParameters = {
      nonce: decodedTx.nonce,
      // gasPrice: decodedTx.gasPrice,
      // maxFeePerGas: decodedTx.gasLimit,
      to: decodedTx.to as `0x${string}`,
      value: parseEther(amount),
      data: decodedTx.data as `0x${string}`,
      // chain: mainnet,
      chainId: mainnet.id,
      gas: undefined,
    };

    trackEvent(STADER_RESTAKE_INITIATE, {
      stake_amount_entered: parseFloat(amount),
    });

    try {
      const hash = await sendTransaction(wagmiConfig, viemTx);
      const txReceipt = await publicClient?.waitForTransactionReceipt({
        hash,
      });

      trackEvent(STADER_RESTAKE_COMPLETE, {
        stake_amount_entered: parseFloat(amount),
        step: "step_1",
      });

      activeIndex += 1;
      setStaderLedgerTxn({
        ...staderLedgerTxn,
        activeIndex,
        hash: txReceipt?.transactionHash || "",
      });
    } catch (error: any) {
      console.log("Error ", error);

      const errorMessage = error?.error || error?.message;
      trackEvent(STADER_RESTAKE_FAILED, {
        stake_amount_entered: parseFloat(amount),
        step: "step_1",
        reason: isErrorRejectedByUser(error) ? "user rejected" : errorMessage,
      });

      setStaderLedgerTxn({
        ...staderLedgerTxn,
        activeIndex,
        error: errorMessage || "Error converting to ETHx",
        hasUserDenied: isErrorRejectedByUser(error),
      });
      return;
    }

    // Step 2

    const token = config.tokens[ETHX];

    const exchangeRateToEth = await axios.get(
      "https://universe.kelpdao.xyz/eth/exchangeRate"
    );

    const ethxAmount = amount * (1 / exchangeRateToEth.data.value);

    const allowanceData = await readContract(wagmiConfig, {
      ...token,
      functionName: "allowance",
      args: [address, config.interactionPools.lrtDepositPool.address],
    });

    const ethxAllowance = +toFixedWithoutRounding(
      allowanceData
        ? formatUnits(allowanceData as bigint, config.tokens[ETHX].decimals)
        : "0",
      8
    );

    if (
      ethxAllowance &&
      Number(ethxAllowance) > tokensBalances[ETHX] + ethxAmount
    ) {
      activeIndex += 1;
      setStaderLedgerTxn({
        ...staderLedgerTxn,
        activeIndex,
        hash: "",
      });

      trackEvent(STADER_RESTAKE_COMPLETE, {
        stake_amount_entered: parseFloat(amount),
        step: "step_2_skiped",
      });
    } else {
      // Step for approval

      try {
        const hash = await writeContractAction(wagmiConfig, {
          ...token,
          functionName: "approve",
          args: [
            config.interactionPools["lrtDepositPool"].address,
            parseEther(APPROVAL_AMOUNT),
          ],
        });

        const txReceipt = await publicClient?.waitForTransactionReceipt({
          hash,
        });

        trackEvent(STADER_RESTAKE_COMPLETE, {
          stake_amount_entered: parseFloat(amount),
          step: "step_2",
        });

        activeIndex += 1;
        setStaderLedgerTxn({
          ...staderLedgerTxn,
          activeIndex,
          hash: txReceipt?.transactionHash || "",
        });
      } catch (error: any) {
        console.log("Error ", error);

        const errorMessage = error?.error || error?.message;

        trackEvent(STADER_RESTAKE_FAILED, {
          stake_amount_entered: parseFloat(amount),
          step: "step_2",
          reason: isErrorRejectedByUser(error) ? "user rejected" : errorMessage,
        });

        setStaderLedgerTxn({
          ...staderLedgerTxn,
          activeIndex,
          error: errorMessage || "Error approving ETHx",
          hasUserDenied: isErrorRejectedByUser(error),
        });
        return;
      }
    }

    // Step 3 convert ETHX to rsETH

    try {
      const params = {
        functionName: "depositAsset",
        args: [
          config.tokens[ETHX].address,
          parseEther(ethxAmount?.toString()),
          0,
          sha256(referralId),
        ],
      };

      const hash = await writeContractAction(wagmiConfig, {
        ...params,
        ...config.interactionPools.lrtDepositPool,
      });

      const txReceipt = await publicClient?.waitForTransactionReceipt({
        hash,
      });

      trackEvent(STADER_RESTAKE_COMPLETE, {
        stake_amount_entered: parseFloat(amount),
        step: "step_3",
      });

      setStaderLedgerTxn({
        ...staderLedgerTxn,
        activeIndex: activeIndex + 1,
        hash: txReceipt?.transactionHash || "",
      });
    } catch (error: any) {
      console.log("Error ", error);

      const errorMessage = error?.error || error?.message;
      trackEvent(STADER_RESTAKE_FAILED, {
        stake_amount_entered: parseFloat(amount),
        step: "step_3",
        reason: isErrorRejectedByUser(error) ? "user rejected" : errorMessage,
      });

      setStaderLedgerTxn({
        ...staderLedgerTxn,
        activeIndex,
        error: errorMessage || "Error converting rsETH",
        hasUserDenied: isErrorRejectedByUser(error),
      });
    }
    return;
  };

  const handleVaultWithdraw = async (data: any) => {
    const { amount } = data;
    initiateNewTxn();
    if (address && signer) {
      let params = {
        functionName: "requestRedeem",
        args: [parseEther(amount), address, address],
      };

      const token = vaultsContractConfig.xToken;

      try {
        writeContract(
          {
            ...vaultsContractConfig.interactionPool,
            ...params,
          },
          {
            onError: (error: any) => {
              trackEvent(VAULT_WITHDRAW_FAILED, {
                vault_amount_entered: parseFloat(amount),
                reason: isErrorRejectedByUser(error)
                  ? "user rejected"
                  : error?.error || error?.message,
              });
              handleErrror(
                error?.error || error?.message,
                isErrorRejectedByUser(error)
              );
              // dispatch(
              //   updateStakeData({
              //     isStaking: false,
              //     isTransactionProcessing: false,
              //   })
              // );
            },
            onSuccess: (hash) => {
              const txDetails = {
                hash,
                wait: () => publicClient?.waitForTransactionReceipt({ hash }),
              };

              updateTransactionDetails(
                txDetails as any,
                TXN_TYPES.VAULT_WITHDRAW,
                amount,
                selectedERC20Vault === ETH ? ETH : token.symbol
              );

              // trackEvent(VAULT_WITHDRAW_SUCCESSFUL, {
              //   vault_amount_entered: parseFloat(amount),
              // });
            },
          }
        );
      } catch (error: any) {
        console.log(error);
        // trackEvent(STAKE_FAILED, {
        //   stake_amount_entered: parseFloat(amount),
        //   reason: isErrorRejectedByUser(error)
        //     ? "user rejected"
        //     : error?.error || error?.message,
        // });
        handleErrror(
          error?.error || error?.message,
          isErrorRejectedByUser(error)
        );
        // dispatch(
        //   updateStakeData({
        //     isStaking: false,
        //     isTransactionProcessing: false,
        //   })
        // );
      }
    }
  };

  const handleVaultClaim = async (data: any) => {
    const { year, month, day, amount } = data;
    initiateNewTxn();

    if (address && signer) {
      let params = {
        functionName: "claim",
        args: [year, month, day, address],
      };

      try {
        writeContract(
          {
            ...vaultsContractConfig.interactionPool,
            ...params,
          },
          {
            onError: (error: any) => {
              console.log(error);

              trackEvent(VAULT_CLAIM_FAILED, {
                amount,
                year,
                month,
                day,
                reason: isErrorRejectedByUser(error)
                  ? "user rejected"
                  : error?.error || error?.message,
              });
              handleErrror(
                error?.error || error?.message,
                isErrorRejectedByUser(error)
              );
            },
            onSuccess: (hash) => {
              const txDetails = {
                hash,
                wait: () => publicClient?.waitForTransactionReceipt({ hash }),
              };

              // trackEvent(VAULT_CLAIM_SUCCESSFUL, {
              //   amount,
              //   year,
              //   month,
              //   day,
              // });

              updateTransactionDetails(
                txDetails as any,
                TXN_TYPES.VAULT_CLAIM,
                amount,
                vaultsContractConfig.xToken.symbol
              );
            },
          }
        );
      } catch (error: any) {
        trackEvent(VAULT_CLAIM_FAILED, {
          amount,
          year,
          month,
          day,
          reason: isErrorRejectedByUser(error)
            ? "user rejected"
            : error?.error || error?.message,
        });
        handleErrror(
          error?.error || error?.message,
          isErrorRejectedByUser(error)
        );
        // dispatch(
        //   updateStakeData({
        //     isClaiming: false,
        //     isTransactionProcessing: false,
        //   })
        // );
      }
    }
  };

  const handleVaultDeposit = async (data: any) => {
    const { amount, referralId } = data;

    const VAULT_ERC20 = [ETHX, STETH];

    initiateNewTxn();
    if (address && signer) {
      let params: any = {};

      if (VAULT_ERC20.includes(selectedERC20Vault)) {
        params = {
          functionName: "getRSETHWithERC20",
          args: [
            config.tokens[selectedERC20Vault].address,
            parseEther(amount?.toString()),
            sha256(referralId),
          ],
        };
      } else if (selectedERC20Vault === "rsETH") {
        params = {
          functionName: "depositRsETH",
          args: [parseEther(amount), sha256(referralId)],
        };
      } else if (selectedERC20Vault === ETH) {
        params = {
          functionName: "getRSETHWithETH",
          args: [sha256(referralId)],
          value: parseEther(amount),
        };
      }

      const token =
        selectedERC20Vault === "rsETH"
          ? config.xtoken
          : config.tokens[selectedERC20Vault];

      try {
        writeContract(
          {
            ...vaultsContractConfig.rsETHAdapterPool,
            ...params,
          },
          {
            onError: (error: any) => {
              trackEvent(VAULT_DEPOSIT_FAILED, {
                vault_amount_entered: parseFloat(amount),
                vault_asset: selectedERC20Vault,
                reason: isErrorRejectedByUser(error)
                  ? "user rejected"
                  : error?.error || error?.message,
              });
              handleErrror(
                error?.error || error?.message,
                isErrorRejectedByUser(error)
              );
              // dispatch(
              //   updateStakeData({
              //     isStaking: false,
              //     isTransactionProcessing: false,
              //   })
              // );
            },
            onSuccess: (hash) => {
              const txDetails = {
                hash,
                wait: () => publicClient?.waitForTransactionReceipt({ hash }),
              };

              updateTransactionDetails(
                txDetails as any,
                TXN_TYPES.VAULT_DEPOSIT,
                amount,
                selectedERC20Vault === ETH ? ETH : token.symbol
              );

              // trackEvent(VAULT_DEPOSIT_SUCCESSFUL, {
              //   stake_amount_entered: parseFloat(amount),
              //   vault_amount_entered: parseFloat(amount),
              //   vault_asset: selectedERC20Vault,
              // });
            },
          }
        );
      } catch (error: any) {
        console.log(error);
        trackEvent(VAULT_DEPOSIT_FAILED, {
          vault_amount_entered: parseFloat(amount),
          vault_asset: selectedERC20Vault,
          reason: isErrorRejectedByUser(error)
            ? "user rejected"
            : error?.error || error?.message,
        });
        handleErrror(
          error?.error || error?.message,
          isErrorRejectedByUser(error)
        );
      }
    }
  };

  const handleStake = async (data: any) => {
    if (!isRestakeSupported) {
      // eslint-disable-next-line no-console
      console.log("restake not supported on chain", config.chain.name);
      return;
    }

    if (!wagmiChain) {
      toast({
        description: "invalid chain",
        status: "error",
        duration: 5000,
        position: "top",
      });
      // eslint-disable-next-line no-console
      console.log("invalid chain");
      return;
    }

    if (wagmiChain?.id !== config.chain.id) {
      toast({
        description: "chain and config mismatch. Please refresh",
        status: "error",
        duration: 5000,
        position: "top",
      });
      // eslint-disable-next-line no-console
      console.log(`chain and config mismatch. Please refresh`);
      return;
    }

    const { amount, referralId, isStaderLedgerUser } = data;

    if (isStaderLedgerUser && selectedERC20 === ETH) {
      handleStakeForStaderLedger({
        amount,
        referralId,
      });
      return;
    }

    initiateNewTxn();

    if (amount === formatNumber(Number(tokenAmount), false, 4, true)) {
      trackEvent(MAX_STAKE, {
        amount,
      });
    }
    if (address && signer) {
      dispatch(
        updateStakeData({ isStaking: true, isTransactionProcessing: true })
      );

      let params;

      if (isEthereumChain) {
        params =
          selectedERC20 === ETH
            ? {
                functionName: "depositETH",
                args: [0, sha256(referralId)],
                value: parseEther(amount),
              }
            : {
                functionName: "depositAsset",
                args: [
                  config.tokens[selectedERC20].address,
                  parseEther(amount),
                  0,
                  sha256(referralId),
                ],
              };
      } else if (chain.id === arbitrum.id) {
        const isETH = selectedERC20 === ETH;

        if (isETH) {
          params = {
            functionName: "deposit",
            args: [sha256(referralId)],
            value: parseEther(amount),
          };
        } else {
          params = {
            functionName: "deposit",
            args: [
              config.tokens[selectedERC20].address,
              parseEther(amount),
              sha256(referralId),
            ],
          };
        }
      } else if (chain.id === xLayer.id) {
        params = {
          functionName: "deposit",
          args: [
            config.tokens[selectedERC20].address,
            parseEther(amount),
            sha256(referralId),
          ],
        };
      } else {
        params = {
          functionName: "deposit",
          args: [sha256(referralId)],
          value: parseEther(amount),
        };
      }

      try {
        writeContract(
          {
            ...config.interactionPools.lrtDepositPool,
            ...params,
          },
          {
            onError: (error: any) => {
              console.log(error);
              trackEvent(STAKE_FAILED, {
                stake_amount_entered: parseFloat(amount),
                reason: isErrorRejectedByUser(error)
                  ? "user rejected"
                  : error?.error || error?.message,
              });
              handleErrror(
                error?.error || error?.message,
                isErrorRejectedByUser(error)
              );
              dispatch(
                updateStakeData({
                  isStaking: false,
                  isTransactionProcessing: false,
                })
              );
            },
            onSuccess: (hash) => {
              const txDetails = {
                hash,
                wait: () => publicClient?.waitForTransactionReceipt({ hash }),
              };

              updateTransactionDetails(
                txDetails as any,
                TXN_TYPES.STAKE,
                amount,
                selectedERC20 === ETH
                  ? ETH
                  : config.tokens[selectedERC20].symbol
              );

              trackEvent(STAKE_SUCCESSFUL, {
                stake_amount_entered: parseFloat(amount),
              });
            },
          }
        );
      } catch (error: any) {
        console.log(error);
        trackEvent(STAKE_FAILED, {
          stake_amount_entered: parseFloat(amount),
          reason: isErrorRejectedByUser(error)
            ? "user rejected"
            : error?.error || error?.message,
        });
        handleErrror(
          error?.error || error?.message,
          isErrorRejectedByUser(error)
        );
        dispatch(
          updateStakeData({
            isStaking: false,
            isTransactionProcessing: false,
          })
        );
      }
    }
  };

  const handleUnstake = async (data: any) => {
    // const { refetchBufferData } = useBufferPool();
    // const { bufferPool } = useUnstakeInfo();
    const { amount, asset } = data;

    // refetchBufferData();

    if (!isWithdrawSupported) {
      // eslint-disable-next-line no-console
      console.log("unstake not supported on chain", config.chain.name);
      return;
    }

    // if (bufferPool && isBufferSufficient && amount >= bufferPool) {
    //   toast({
    //     description:
    //       "Buffer amount changed and Unstake requests are processed in 7-10 days",
    //     status: "warning",
    //     duration: 5000,
    //     position: "top",
    //   });
    // }

    if (!wagmiChain) {
      toast({
        description: "invalid chain",
        status: "error",
        duration: 5000,
        position: "top",
      });
      // eslint-disable-next-line no-console
      console.log("invalid chain");
      return;
    }

    if (wagmiChain?.id !== config.chain.id) {
      toast({
        description: "chain and config mismatch. Please refresh",
        status: "error",
        duration: 5000,
        position: "top",
      });
      // eslint-disable-next-line no-console
      console.log(`chain and config mismatch. Please refresh`);
      return;
    }

    initiateNewTxn();

    if (amount === formatNumber(Number(tokenAmount), false, 4, true)) {
      trackEvent(MAX_UNSTAKE, {
        amount,
      });
    }
    if (address && signer) {
      dispatch(
        updateStakeData({ isUnstaking: true, isTransactionProcessing: true })
      );

      try {
        writeContract(
          {
            ...config.interactionPools.lrtWithdrawalManager,
            functionName: "initiateWithdrawal",
            args: [asset, parseEther(amount)],
          },
          {
            onError: (error: any) => {
              console.log(error);
              trackEvent(UNSTAKE_FAILED, {
                unstake_amount_entered: parseFloat(amount),
                reason: isErrorRejectedByUser(error)
                  ? "user rejected"
                  : error?.error || error?.message,
              });
              handleErrror(
                error?.error || error?.message,
                isErrorRejectedByUser(error)
              );
              dispatch(
                updateStakeData({
                  isUnstaking: false,
                  isTransactionProcessing: false,
                })
              );
            },
            onSuccess: (hash) => {
              const txDetails = {
                hash,
                wait: () => publicClient?.waitForTransactionReceipt({ hash }),
              };

              updateTransactionDetails(
                txDetails as any,
                TXN_TYPES.UNSTAKE,
                amount,
                config.xtoken.symbol
              );
            },
          }
        );
      } catch (error: any) {
        console.log(error);
        trackEvent(UNSTAKE_FAILED, {
          stake_amount_entered: parseFloat(amount),
          reason: isErrorRejectedByUser(error)
            ? "user rejected"
            : error?.error || error?.message,
        });
        handleErrror(
          error?.error || error?.message,
          isErrorRejectedByUser(error)
        );
        dispatch(
          updateStakeData({
            isUnstaking: false,
            isTransactionProcessing: false,
          })
        );
      }
    }
  };

  const handleClaim = async (data: any) => {
    if (!isWithdrawSupported) {
      // eslint-disable-next-line no-console
      console.log("claim not supported on chain", config.chain.name);
      return;
    }

    if (!wagmiChain) {
      toast({
        description: "invalid chain",
        status: "error",
        duration: 5000,
        position: "top",
      });
      // eslint-disable-next-line no-console
      console.log("invalid chain");
      return;
    }

    if (wagmiChain?.id !== config.chain.id) {
      toast({
        description: "chain and config mismatch. Please refresh",
        status: "error",
        duration: 5000,
        position: "top",
      });
      // eslint-disable-next-line no-console
      console.log(`chain and config mismatch. Please refresh`);
      return;
    }

    const { amount, asset, callback } = data;
    initiateNewTxn();

    if (address && signer) {
      dispatch(
        updateStakeData({ isClaiming: true, isTransactionProcessing: true })
      );

      try {
        writeContract(
          {
            ...config.interactionPools.lrtWithdrawalManager,
            functionName: "completeWithdrawal",
            args: [asset],
          },
          {
            onError: (error: any) => {
              console.log(error);
              callback?.();
              trackEvent(CLAIM_FAILED, {
                amount,
                asset,
                reason: isErrorRejectedByUser(error)
                  ? "user rejected"
                  : error?.error || error?.message,
              });
              handleErrror(
                error?.error || error?.message,
                isErrorRejectedByUser(error)
              );
              dispatch(
                updateStakeData({
                  isClaiming: false,
                  isTransactionProcessing: false,
                })
              );
            },
            onSuccess: (hash) => {
              const txDetails = {
                hash,
                wait: () => publicClient?.waitForTransactionReceipt({ hash }),
              };

              updateTransactionDetails(
                txDetails as any,
                TXN_TYPES.CLAIM,
                amount,
                config.xtoken.symbol
              );
              callback?.();
            },
          }
        );
      } catch (error: any) {
        console.log(error);
        trackEvent(CLAIM_FAILED, {
          claim_amount: parseFloat(amount),
          reason: isErrorRejectedByUser(error)
            ? "user rejected"
            : error?.error || error?.message,
        });
        handleErrror(
          error?.error || error?.message,
          isErrorRejectedByUser(error)
        );
        dispatch(
          updateStakeData({
            isClaiming: false,
            isTransactionProcessing: false,
          })
        );
      }
    }
  };

  const attachEventListners = (event: any) => {
    switch (event.name) {
      case EVENTS.HANDLE_ERC20_APPROVE: {
        handleApproveToken(false, event.data);
        break;
      }
      case EVENTS.HANDLE_VAULT_APPROVE: {
        handleVaultApprove();
        break;
      }
      case EVENTS.HANDLE_XTOKEN_APPROVE: {
        handleApproveToken(true, event.data);
        break;
      }
      case EVENTS.HANDLE_VAULT_CLAIM: {
        handleVaultClaim(event.data);
        break;
      }
      // case EVENTS.HANDLE_VAULT_XTOKEN_APPROVE: {
      //   handleVaultXTokenApprove();
      //   break;
      // }
      case EVENTS.OPEN_WALLET_MODAL: {
        openWalletModal();
        break;
      }
      case EVENTS.SWITCH_NETWORK: {
        switchChain({ chainId: chains[0].id });
        break;
      }
      case EVENTS.HANDLE_STAKE: {
        handleStake(event.data);
        break;
      }
      case EVENTS.HANDLE_VAULT_DEPOSIT: {
        handleVaultDeposit(event.data);
        break;
      }
      // case EVENTS.HANDLE_VAULT_CLAIM: {
      //   handleVaultClaim(event.data);
      //   break;
      // }
      case EVENTS.HANDLE_VAULT_WITHDRAW: {
        handleVaultWithdraw(event.data);
        break;
      }
      case EVENTS.HANDLE_UNSTAKE: {
        handleUnstake(event.data);
        break;
      }
      case EVENTS.HANDLE_CLAIM: {
        handleClaim(event.data);
        break;
      }
    }
  };

  useEffect(() => {
    attachEventListners(emitter);
  }, [emitter]);

  useEffect(() => {
    if (address && activeConnector?.id) {
      dispatch(
        updateWalletData({
          walletAddress: address,
          walletName: activeConnector.id,
        })
      );
    }
  }, [activeConnector?.id, address]);

  const handleTxViewOnEtherScan = () => {
    trackEvent(VIEW_ADDRESS_ON_SCANNER, {
      cta_location: "tx_modal",
    });
    const url = `${chain!.blockExplorers?.default.url}/tx/${txnDetail.hash}`;
    window.open(url, "_blank");
  };

  return (
    <>
      {modalType === MODAL_TYPES.CONNECT_WALLET && (
        <WalletConnectModal
          isOpen={
            modalType === MODAL_TYPES.CONNECT_WALLET &&
            !isMobileView &&
            !isConnected
          }
          closeModal={handleCloseModal}
          onConnectWallet={onWalletConnect}
        />
      )}

      <AccountModal
        isOpen={modalType === MODAL_TYPES.WALLET_MODAL && isConnected}
        onCancel={onCloseAccountModal}
        walletAddress={address}
        connectorId={activeConnector?.id || ""}
        scannerLink={`${chain!.blockExplorers?.default.url}/address/${address}`}
      />
      {children}

      {modalType === MODAL_TYPES.WALLET_CONNECT_MESSAGE_MODAL && (
        <WalletMessageModal
          isOpen={modalType === MODAL_TYPES.WALLET_CONNECT_MESSAGE_MODAL}
          closeModal={handleCloseModal}
          error={errorMessage || ""}
          onSubmitPrimary={() => emitReduxEvent(EVENTS.SWITCH_NETWORK)}
        />
      )}

      {staderLedgerTxn.activeIndex >= 0 && (
        <StaderLedgerTxModal
          isOpen={staderLedgerTxn.activeIndex >= 0}
          activeIndex={staderLedgerTxn.activeIndex}
          closeAlert={() => {
            setStaderLedgerTxn(initialStaderLedgerTxn);
          }}
          error={staderLedgerTxn.error}
          hasUserDenied={staderLedgerTxn.hasUserDenied}
        />
      )}

      {txnLoader && (
        <TransactionModal
          isOpen={txnLoader}
          hash={txnDetail.hash}
          isTxnProcessing={txnDetail.status === TXN_STATUSES.PENDING}
          error={txnError.promptError}
          daysToWaitForWithdraw={selectedERC20 === ETH ? "~14-16" : "~7-10"}
          hasUserDenied={txnError.hasUserDenied}
          closeAlert={resetTxns}
          handleTxView={handleTxViewOnEtherScan}
          transactionType={txnDetail.type}
          chainId={chain!.id}
          amount={txnDetail.amount}
        />
      )}
    </>
  );
};

export default Services;
