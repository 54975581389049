import Image from "next/image";
import { ImageProps } from "next/image";

import { getUrlForLocalImage, getUrlForStaticImage } from "utils";
import ExternalImage from "./ExternalImage";

export const BaseImage = ({ src, ...rest }: ImageProps) => {
  const isExternal = (src as string)?.startsWith?.("http");
  const isStaticImage = typeof src === "object";

  const computedSrc = isExternal
    ? (src as string)
    : isStaticImage
    ? getUrlForStaticImage(src)
    : getUrlForLocalImage(src);

  if (isExternal) {
    return (
      <ExternalImage
        src={computedSrc}
        alt={rest.alt}
        h={`${rest.height}px`}
        w={`${rest.width}px`}
      />
    );
  }

  return <Image priority src={computedSrc} {...rest} />;
};

export default BaseImage;
