export const CONNECT_WALLET = "connect_wallet";
export const DISCONNECT_WALLET = "disconnect_wallet";
export const TERMS_OF_SERVICES = "terms_of_services";

export const APPROVE_STAKING = "approve_staking";
export const APPROVE_UNSTAKING = "approve_unstaking";

export const STAKE_ENTERAMOUNT = "stake_enteramount";
export const MAX_STAKE = "max_stake";
export const MAX_UNSTAKE = "max_unstake";
export const STAKE_CTA = "stake_cta";
export const UNSTAKE_CTA = "unstake_cta";
export const CLAIM_CTA = "claim_cta";
export const STAKE_SUCCESSFUL = "stake_successful";
export const STAKE_FAILED = "stake_failed";
export const UNSTAKE_SUCCESSFUL = "unstake_successful";
export const UNSTAKE_FAILED = "unstake_failed";
export const CLAIM_SUCCESSFUL = "claim_successful";
export const CLAIM_FAILED = "claim_failed";

// vault
export const VAULT_DEPOSIT_SUCCESSFUL = "vault_deposit_successful";
export const VAULT_WITHDRAW_SUCCESSFUL = "vault_withdraw_successful";
export const VAULT_CLAIM_SUCCESSFUL = "vault_claim_successful";
export const VAULT_DEPOSIT_FAILED = "vault_deposit_failed";
export const VAULT_WITHDRAW_FAILED = "vault_withdraw_failed";
export const VAULT_CLAIM_FAILED = "vault_claim_failed";
export const VAULT_LISTING_CTA = "vault_listing_cta";

export const TOKENX_APPROVE_SUCCESSFUL = "tokenx_approve_successful";
export const TOKENX_APPROVE_FAILED = "tokenx_approve_failed";

export const TOKEN_APPROVE_SUCCESSFUL = "token_approve_successful";
export const TOKEN_APPROVE_FAILED = "token_approve_failed";

export const TOKEN_SELECTION = "token_selection";
export const ADD_RSETH = "add_rsETH";

export const CLICK_SUB_HEADER = "click_sub_header";
export const VIEW_ADDRESS_ON_SCANNER = "view_address_on_scanner";
export const COPY_ACCOUNT_ADDRESS = "copy_account_address";

export const WALLET_CONNECTED_SUCCESSFULLY = "wallet_connected_successfully";

// Unstake
export const UNSTAKE_ENTER_AMOUNT = "unstake_enter_amount";
//Wallet_address, token_Balance, amount_entered, current_token, chain

// stake TX modal
export const POST_DEPOSIT_DEFI_EXPLORE = "post_deposit_defi_explore";

// Wrap rsETH
export const APPROVE_WRAPPING = "approve_wrapping";
export const WRAP_UNWRAP_ENTER_AMOUNT = "wrap_unwrap_enter_amount";
export const MAX_WRAP_UNWRAP = "max_wrap_unwrap";
export const TOGGLE_WRAP_UNWRAP = "toggle_wrap_unwrap";
export const WRAP_UNWRAP_CTA = "wrap_unwrap_cta";

// Dashboard
export const COPY_REFERRAL_LINK = "copy_referral_link";
export const KELP_GRAND_MILES_CONVERSION = "kelp_grand_miles_conversion";
export const REFERRAL_INFO = "referral_info";

// Bridge rsETH
export const BRIDGE_TO_CHAIN = "bridge_to_chain";

// Claim KEP
export const CLAIM_KEP = "claim_kep";
export const CLAIM_KEP_CLICK = "claim_kep_click";
export const CLAIMABLE_EL_POINTS = "claimable_el_points";
export const FEE = "fee";

// Stader Ledger Users
export const STADER_RESTAKE_COMPLETE = "stader_restake_complete";
export const STADER_RESTAKE_FAILED = "stader_restake_failed";
export const STADER_RESTAKE_INITIATE = "stader_restake_initiate";

//Defi
export const DEFI_SELECT_CHAIN = "select_chain";
export const DEFI_ADD_LIQUIDITY = "add_liquidity";
export const DEFI_SWAP = "swap";
export const DEFI_WITHDRAW = "defi_withdraw";

// Landing page headers click
export const LP_PRODUCTS = "LP_Products";
export const LP_NETWORKS = "LP_Networks";
export const LP_AVS = "LP_AVS";
export const LP_ABOUT = "LP_About";
export const LP_INSIGHTS = "LP_Insights";
export const LP_RESOURCES = "LP_Resources";
export const LP_RESTAKE = "LP_Restake";
export const LP_SUBSCRIBE = "LP_Subscribe";
export const LP_HEADER = "LP_Header"; // default

//Dashboard Defi Nudge
export const DASHBOARD_DEFI_PROMPT = "dashboard_defi_prompt";

//Institutions
export const INSTITUTION_GET_STARTED = "Institution_GetStarted";
export const INSTITUTION_SUBMIT = "Insititution_formSubmit";
