import { Center, Text, ToastPosition, useToast } from "@chakra-ui/react";

import { TransactionType } from "@constants/common";

import CloseIcon from "@components/CloseIcon";
import ModalDrawer from "@components/ModalDrawer";
import SuccessErrorModalBodyTemplate from "@components/SuccessErrorModalBodyTemplate";
import useDappChain from "@hooks/useDappChain";

interface WalletMessageModalProps {
  isOpen: boolean;
  error: string;
  onSubmitPrimary: () => void;
  closeModal: () => void;
}

const defaultToastProps = {
  duration: 5000,
  position: "top" as ToastPosition,
  isClosable: true,
};

const WalletMessageModal = ({
  isOpen,
  error,
  onSubmitPrimary,
  closeModal,
}: WalletMessageModalProps) => {
  const toast = useToast();

  const { unsupported } = useDappChain();

  const renderContent = () => {
    if (error) {
      if (!toast.isActive("error-toast")) {
        toast({
          description: (
            <Text fontWeight={600} fontSize="16px">
              {error}
            </Text>
          ),
          status: "error",
          id: "error-toast",
          onCloseComplete: closeModal,
          ...defaultToastProps,
        });
      }
      return <></>;
    } else {
      if (unsupported) {
        return (
          <ModalDrawer isOpen={isOpen} closeAlert={closeModal}>
            <Center
              padding={{ base: "0 1rem 2.5rem", md: "2.5rem 3rem" }}
              fontWeight="600"
              flexDirection="column"
            >
              <CloseIcon onClose={closeModal} />
              <SuccessErrorModalBodyTemplate
                transactionType={TransactionType.WARNING}
                modalTitle="Unsupported network"
                primaryBtnTxt="Switch Network"
                onSubmitPrimary={onSubmitPrimary}
              />
            </Center>
          </ModalDrawer>
        );
      }
      if (!toast.isActive("success-toast")) {
        toast({
          description: (
            <Text fontWeight={600} fontSize="16px">
              Wallet connected successfully
            </Text>
          ),
          status: "success",
          id: "success-toast",
          onCloseComplete: closeModal,
          ...defaultToastProps,
        });
      }
      return <></>;
    }
  };

  return <>{renderContent()}</>;
};

export default WalletMessageModal;
