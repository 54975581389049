import { formatEther } from "@ethersproject/units";
import { SideBarDataType } from "./types";
import {
  ETH,
  ETHX,
  STETH,
  contractConfig,
  TAGS,
  WETH,
  WSTETH,
  SFRXETH,
} from "@config/contractsConfig";
import { supportedChains } from "@services/WagmiProvider";

export const WALLET_KEYS = {
  COINBASEWALLET: "coinbasewallet",
  METAMASK: "metamask",
  WALLETCONNECT: "walletconnect",
  TRUSTWALLET: "trustwallet",
  SAFEPAL: "safepal",
  MATHWALLET: "mathwallet",
  LEDGER_LIVE: "ledgerLive",
  RABBYWALLET: "rabbywallet",
  OKX: "okx",
};

export const HIDDEN_WALLETS = [WALLET_KEYS.LEDGER_LIVE];

export const wallets = [
  WALLET_KEYS.METAMASK,
  WALLET_KEYS.OKX,
  WALLET_KEYS.WALLETCONNECT,
  WALLET_KEYS.COINBASEWALLET,
  WALLET_KEYS.RABBYWALLET,
  WALLET_KEYS.MATHWALLET,
  WALLET_KEYS.TRUSTWALLET,
  WALLET_KEYS.SAFEPAL,
  WALLET_KEYS.LEDGER_LIVE,
];

export enum TransactionType {
  ERROR = "ERROR",
  SUCCESS = "SUCCESS",
  WARNING = "WARNING",
}

export interface IIndexable {
  [key: string]: any;
}

export interface IIndexableNumber {
  [key: number]: any;
}

export const walletLabelMapping: IIndexable = {
  injected: "Metamask",
  metamask: "Metamask",
  coinbasewallet: "Coinbase Wallet",
  walletconnect: "Wallet Connect",
  trustwallet: "Trust Wallet",
  safepal: "SafePal Wallet",
  mathwallet: "Math Wallet",
  ledgerLive: "Ledger Live",
  rabbywallet: "Rabby Wallet",
  okex: "OKX Wallet",
};

export const walletIconMapping: IIndexable = {
  injected: "/assets/wallets/injected.svg",
  metamask: "/assets/wallets/mm.png",
  coinbasewallet: "/assets/wallets/cbw.png",
  walletconnect: "/assets/wallets/wc.png",
  trustwallet: "/assets/wallets/trust.jpeg",
  safepal: "/assets/wallets/safepal.jpeg",
  mathwallet: "/assets/wallets/math.png",
  ledgerlive: "/assets/wallets/ledger.png",
  rabbywallet: "/assets/wallets/rabby.jpeg",
  okex: "/assets/wallets/okex.png",
};

export const TXN_TYPES: IIndexable = {
  TOKEN_APPROVE: "Token_approve",
  ERC20_APPROVE: "ERC20_approve",
  XTOKEN_APPROVE: "XToken_approve",
  STAKE: "Stake",
  UNSTAKE: "Unstake",
  CLAIM: "Claim",
  XTOKEN_ASSOCIATE: "XToken_associate",
  VAULT_DEPOSIT: "Vault_deposit",
  VAULT_WITHDRAW: "Vault_withdraw",
  VAULT_CLAIM: "Vault_claim",
};

export const TXN_STATUSES: IIndexable = {
  PENDING: "PENDING",
  SUCCESS: "SUCCESS",
  ERROR: "ERROR",
};

export const CONNECT_WALLET_CTA = "Connect Wallet";
export const RESTAKE_NOW = "Restake now";
export const UNSTAKE_NOW = "Unstake now";
export const WITHDRAW_CTA = "Withdraw";
export const CLAIM_KEP = "Claim KEP";
export const APPROVE_CTA = "Approve";
export const SWITCH_NETWORK = "Switch Network";

export const formatEtherNumber = (value: string | undefined, decimals = 4) => {
  if (!value) {
    return -1;
  }
  const number = toFixedWithoutRounding(
    formatEther(value.toString()),
    decimals
  );

  return +number;
};

export const toFixedWithoutRounding = (value: string, decimals: number) =>
  (value.match(new RegExp(`^-?\\d+(?:.\\d{0,${decimals}})?`)) as string[])[0];

export const isNumber = (str: string) => {
  console.log("str", str, typeof str);
  if (str.toString().trim() === "") {
    return false;
  }
  return !isNaN(+str);
};

export const APPROVAL_AMOUNT = "100000";

export const MAX_FRACTION_DIGITS = 4;

export const COMMUNITY_DETAILS = [
  {
    key: "twitter",
    name: "Twitter",
    link: "https://twitter.com/KelpDAO",
  },
  {
    key: "telegram",
    name: "Telegram",
    link: "https://t.me/KelpDAOxyz",
  },
  {
    key: "discord",
    name: "Discord",
    link: "https://discord.gg/wfDdcmbMjN",
  },
  {
    key: "docs",
    name: "Docs",
    link: "https://kelp.gitbook.io/kelp",
  },
];

export const SUB_HEADER_OPTIONS = [
  ...COMMUNITY_DETAILS,
  {
    key: "tos",
    name: "Terms of service",
    link: "https://docs.google.com/document/d/1eSIGFBIZhsH35fZ-EL_UqlK_8f08Vjup/",
  },
  {
    key: "risk",
    name: "Risks & Disclaimer",
    link: "https://kelp.gitbook.io/kelp/risks-and-disclaimer",
  },
];

export const getCommunityIcon = (community: string) => {
  switch (community) {
    case "twitter":
      return "/assets/socials/twitter.svg";
    case "telegram":
      return "/assets/socials/telegram.svg";
    case "discord":
      return "/assets/socials/discord.svg";
    case "docs":
      return "/assets/socials/docs.svg";
    case "tos":
      return "/assets/socials/document-hammer.svg";
    case "risk":
      return "/assets/socials/risk.svg";
  }
};

export type LstOptions = {
  key: string;
  title?: string;
  tags?: TAGS[] | [];
  isDisabled?: boolean;
  tag?: string;
  priority?: number;
};

export function getLSTOptionsForChain(
  chainId: number,
  hideETH = false,
  isStaderLedgerUser = false,
  isVaultPage = false
): LstOptions[] {
  const { nativeToken, tokens } =
    contractConfig[chainId] || contractConfig[supportedChains[0].id];

  let list: any = [];

  if (!hideETH) {
    list.push({
      title: nativeToken.symbol,
      key: nativeToken.symbol,
      priority: 0,
      tags: nativeToken.tags,
      ...("unstakePriority" in nativeToken
        ? { unstakePriority: nativeToken.unstakePriority }
        : {}),
    });
  }

  list = [
    ...list,
    ...Object.keys(tokens)
      .filter((key) => {
        return (
          (isStaderLedgerUser ? tokens[key].isLiquidStakeSupported : true) &&
          tokens[key].symbol != "sfrxETH"
        );
      })
      .map((key) => ({
        key,
        title: key,
        tags: tokens[key].tags,
        priority: tokens[key].priority || 0,
        unstakePriority: tokens[key].unstakePriority || 0,
      })),
  ];

  if (isVaultPage) {
    list.push({
      key: "rsETH",
      title: "rsETH",
      tags: [],
      priority: -3,
      unstakePriority: -1,
    });
    list = list.map((data: any) => {
      return {
        ...data,
        priority: data.key === "ETH" ? -2 : data?.priority || 0,
      };
    });
  } else {
    list.push(
      {
        key: "divider",
        tags: [],
        title: "",
      },
      {
        title: "Other LSTs",
        key: "other",
        tag: "Coming soon",
        isDisabled: true,
      }
    );
  }

  return list;
}

export const LSTIconMapping: IIndexable = {
  [STETH]: "/assets/stETH.svg",
  [ETHX]: "/assets/ethx.svg",
  [SFRXETH]: "/assets/sfrx.svg",
  [ETH]: "/assets/eth.svg",
  [WETH]: "/assets/eth.svg",
  [WSTETH]: "/assets/stETH.svg",
  rsETH: "/assets/rseth.png",
};

export const LSTSidebarOptions: SideBarDataType[] = [
  {
    title: "Restake",
    key: "restake",
    link: "/restake",
  },
  {
    title: "Gain",
    key: "gain",
    link: "/gain",
    isNew: true,
    isLedgerEnabld: false,
  },
  {
    title: "Dashboard",
    key: "dashboard",
    link: "/dashboard",
    // isComingSoon: false,
    // isDisabled: false,
  },
  {
    title: "DeFi",
    key: "defi",
    link: "/defi",
  },
  {
    title: "Bridge rsETH",
    key: "bridge",
    link: "/bridge",
  },
  {
    title: "Claim KEP",
    key: "claim-kep",
    link: "/claim-kep",
    // isDisabled: true,
  },
  {
    title: "wrap rsETH",
    key: "wrap",
    link: "/wrap",
  },
  {
    title: "AVS",
    key: "avs",
    link: "/avs",
  },
];

export const AUDITS_DATA = [
  {
    key: "sigmaprime",
    imgLink: "/assets/landingPage/audits/sigma_prime.svg",
    link: "https://kelpdao.xyz/audits/smartcontracts/SigmaPrime.pdf",
  },
  {
    key: "coderena",
    imgLink: "/assets/landingPage/audits/coderena.svg",
    link: "https://code4rena.com/reports/2023-11-kelp",
  },
];

export const REWARDS_DISTRIBUTION_DATA = [
  {
    title: "Ethereum rsETH Holders",
    kelpKey: "kelpMilesForRsEthHoldings",
    elpKey: "elPointsForRsEthHoldings",
    isComingSoon: false,
    tags: ["Ethereum"],
    icon: "/assets/chains/ethereum.svg",
  },
  {
    title: "Arbitrum rsETH Holders",
    kelpKey: "kelpMilesForArbitrumHolder",
    elpKey: "elPointsForArbitrumHolder",
    isComingSoon: false,
    tags: ["Arbitrum"],
    icon: "/assets/chains/arbitrum.svg",
  },
  {
    title: "Optimism rsETH Holders",
    kelpKey: "kelpMilesForOPRsEthHoldings",
    elpKey: "elPointsForOPRsEthHoldings",
    isComingSoon: false,
    tags: ["Optimism"],
    icon: "/assets/chains/optimism.svg",
  },
  {
    title: "Optimism wrsETH Holders",
    kelpKey: "kelpMilesForOPWRsEthHoldings",
    elpKey: "elPointsForOPWRsEthHoldings",
    isComingSoon: false,
    tags: ["Optimism"],
    icon: "/assets/chains/optimism.svg",
  },
  {
    title: "Base rsETH Holders",
    kelpKey: "kelpMilesForBaseRsEthHoldings",
    elpKey: "elPointsForBaseRsEthHoldings",
    isComingSoon: false,
    tags: ["Base"],
    icon: "/assets/chains/base.svg",
  },
  {
    title: "Base wrsETH Holders",
    kelpKey: "kelpMilesForBaseWRsEthHoldings",
    elpKey: "elPointsForBaseWRsEthHoldings",
    isComingSoon: false,
    tags: ["Base"],
    icon: "/assets/chains/base.svg",
  },
  {
    title: "Mode rsETH Holders",
    kelpKey: "kelpMilesForModeRsEthHoldings",
    elpKey: "elPointsForModeRsEthHoldings",
    isComingSoon: false,
    tags: ["Mode"],
    icon: "/assets/chains/mode.svg",
  },
  {
    title: "Mode wrsETH Holders",
    kelpKey: "kelpMilesForModeWRsEthHoldings",
    elpKey: "elPointsForModeWRsEthHoldings",
    isComingSoon: false,
    tags: ["Mode"],
    icon: "/assets/chains/mode.svg",
  },
  {
    title: "Scroll rsETH Holders",
    kelpKey: "kelpMilesForScrollRsEthHoldings",
    elpKey: "elPointsForScrollRsEthHoldings",
    isComingSoon: false,
    tags: ["Scroll"],
    icon: "/assets/chains/scroll.svg",
  },
  {
    title: "Scroll wrsETH Holders",
    kelpKey: "kelpMilesForScrollWRsEthHoldings",
    elpKey: "elPointsForScrollWRsEthHoldings",
    isComingSoon: false,
    tags: ["Scroll"],
    icon: "/assets/chains/scroll.svg",
  },
  {
    title: "Linea rsETH Holders",
    kelpKey: "kelpMilesForLineaRsEthHoldings",
    elpKey: "elPointsForLineaRsEthHoldings",
    isComingSoon: false,
    tags: ["Linea"],
    icon: "/assets/chains/linea.svg",
  },
  {
    title: "Linea wrsETH Holders",
    kelpKey: "kelpMilesForLineaWRsEthHoldings",
    elpKey: "elPointsForLineaWRsEthHoldings",
    isComingSoon: false,
    tags: ["Linea"],
    icon: "/assets/chains/linea.svg",
  },
  {
    title: "100k Bonus",
    kelpKey: "kelpMilesForBonus",
    elpKey: null,
    isComingSoon: false,
    tags: [],
    icon: "/assets/kelp_icon.svg",
  },
  {
    title: "Pendle",
    kelpKey: "kelpMilesForPendle",
    elpKey: "elPointsForPendle",
    isComingSoon: false,
    tags: ["Ethereum"],
    icon: "/assets/platforms/pendle.svg",
  },
  {
    title: "Pendle (25th April)",
    kelpKey: "kelpMilesForPendleArbitrum",
    elpKey: "elPointsForPendleArbitrum",
    isComingSoon: false,
    tags: ["Arbitrum"],
    icon: "/assets/platforms/pendle.svg",
  },
  {
    title: "Pendle (27th June)",
    kelpKey: "kelpMilesForPendleArb27thJune",
    elpKey: "elPointsForPendleArb27thJune",
    isComingSoon: false,
    tags: ["Arbitrum"],
    icon: "/assets/platforms/pendle.svg",
  },
  {
    title: "Zircuit",
    kelpKey: "kelpMilesForZircuitrsETHPendle",
    elpKey: "elPointsForZircuitrsETHPendle",
    isComingSoon: false,
    tags: ["Ethereum"],
    icon: "/assets/platforms/zircuit.png",
  },
  {
    title: "Uniswap (rsETH/ETHx)",
    kelpKey: "kelpMilesForUni",
    elpKey: "elPointsForUni",
    isComingSoon: false,
    tags: ["Ethereum"],
    icon: "/assets/platforms/uni.svg",
  },
  {
    title: "Uniswap (rsETH/WETH)",
    kelpKey: "kelpMilesForUniWEth",
    elpKey: "elPointsForUniWEth",
    isComingSoon: false,
    tags: ["Ethereum"],
    icon: "/assets/platforms/uni.svg",
  },
  {
    title: "Penpie voters",
    kelpKey: "kelpMilesForPenpieVoters",
    elpKey: null,
    isComingSoon: false,
    tags: ["Ethereum"],
    icon: "/assets/platforms/penpie.png",
  },
  {
    title: "Penpie voters",
    kelpKey: "kelpMilesForPenpieVotersArb",
    elpKey: null,
    isComingSoon: false,
    tags: ["Arbitrum"],
    icon: "/assets/platforms/penpie.png",
  },
  {
    title: "Equilibria voters",
    kelpKey: "kelpMilesForEqbVoters",
    elpKey: null,
    isComingSoon: false,
    tags: ["Ethereum", "Arbitrum"],
    icon: "/assets/platforms/equilibria.png",
  },
  {
    title: "Curve",
    kelpKey: "kelpMilesForCurve",
    elpKey: "elPointsForCurve",
    isComingSoon: false,
    tags: ["Ethereum"],
    icon: "/assets/platforms/curve.svg",
  },
  {
    title: "Gearbox",
    kelpKey: "kelpMilesForGearbox",
    elpKey: "elPointsForGearbox",
    isComingSoon: false,
    tags: ["Ethereum"],
    icon: "/assets/platforms/gearbox.png",
  },
  {
    title: "Maverick",
    kelpKey: "kelpMilesForMaverick",
    elpKey: "elPointsForMaverick",
    isComingSoon: false,
    tags: ["Ethereum"],
    icon: "/assets/platforms/maverick.svg",
  },
  {
    title: "Balancer (rsETH/ETHx)",
    kelpKey: "kelpMilesForBalancer",
    elpKey: "elPointsForBalancer",
    isComingSoon: false,
    tags: ["Ethereum"],
    icon: "/assets/platforms/balancer.svg",
  },
  {
    title: "Balancer (rsETH/WETH)",
    kelpKey: "kelpMilesForBalancerWEth",
    elpKey: "elPointsForBalancerWEth",
    isComingSoon: false,
    tags: ["Ethereum"],
    icon: "/assets/platforms/balancer.svg",
  },
  {
    title: "Balancer (rsETH/WETH)",
    kelpKey: "kelpMilesForBalancerZKEVM",
    elpKey: "elPointsForBalancerZKEVM",
    isComingSoon: false,
    tags: ["Polygon zkEVM"],
    icon: "/assets/platforms/balancer.svg",
  },
  {
    title: "Zircuit",
    kelpKey: "kelpMilesForZircuitnDeposit",
    elpKey: "elPointsForZircuitnDeposit",
    isComingSoon: false,
    tags: [],
    icon: "/assets/platforms/zircuit.png",
  },
  {
    title: "VaultCraft",
    kelpKey: "kelpMilesForVault",
    elpKey: "elPointsForVault",
    isComingSoon: false,
    tags: ["Ethereum"],
    icon: "/assets/platforms/vault.svg",
  },
  {
    title: "Prisma",
    kelpKey: "kelpMilesForPrisma",
    elpKey: "elPointsForPrisma",
    isComingSoon: false,
    tags: ["Ethereum"],
    icon: "/assets/platforms/prisma.png",
  },
  {
    title: "Ramses",
    kelpKey: "kelpMilesForRamses",
    elpKey: "elPointsForRamses",
    isComingSoon: false,
    tags: ["Arbitrum"],
    icon: "/assets/platforms/ramses.png",
  },
  {
    title: "Ambient",
    kelpKey: "kelpMilesForScrollAmbient",
    elpKey: "elPointsForScrollAmbient",
    isComingSoon: false,
    tags: ["Scroll"],
    icon: "/assets/platforms/ambient.png",
  },
  {
    title: "Camelot",
    kelpKey: "kelpMilesForCamelot",
    elpKey: "elPointsForCamelot",
    isComingSoon: false,
    tags: ["Arbitrum"],
    icon: "/assets/platforms/camelot.png",
  },
  {
    title: "Kelp Karnival",
    kelpKey: "kelpMilesForKelpKarnival",
    elpKey: "elPointsForKelpKarnival",
    isComingSoon: false,
    tags: ["Ethereum"],
    icon: "/assets/kelp_icon.svg",
  },
  {
    title: "Referral bonus",
    kelpKey: "kelpMilesForReferrals",
    elpKey: null,
    isComingSoon: false,
    tags: [],
    icon: "/assets/kelp_icon.svg",
  },
  {
    title: "Airpuff rsETH Holders",
    kelpKey: "kelpMilesForAirpuffRsEthHoldings",
    elpKey: "elPointsForAirpuffRsEthHoldings",
    isComingSoon: false,
    tags: ["Ethereum"],
    icon: "/assets/platforms/airpuff.png",
  },
  {
    title: "Airpuff (Arb/rsEth) Holders",
    kelpKey: "kelpMilesForAirpuffArbRsEthHoldings",
    elpKey: "elPointsForAirpuffArbRsEthHoldings",
    isComingSoon: false,
    tags: ["Arbitrum"],
    icon: "/assets/platforms/airpuff.png",
  },
  {
    title: "Beefy",
    kelpKey: "kelpMilesForBeefy",
    elpKey: "elPointsForBeefy",
    isComingSoon: false,
    tags: ["Arbitrum"],
    icon: "/assets/platforms/beefy.svg",
  },
  {
    title: "Thruster",
    kelpKey: "kelpMilesForThruster",
    elpKey: "elPointsForThruster",
    isComingSoon: false,
    tags: ["Blast"],
    icon: "/assets/platforms/thurster.png",
  },
  {
    title: "Thruster2",
    kelpKey: "kelpMilesForThruster2",
    elpKey: "elPointsForThruster2",
    isComingSoon: false,
    tags: ["Blast"],
    icon: "/assets/platforms/thurster.png",
  },
  {
    title: "PolygonZkEVM rsEth Holdings",
    kelpKey: "kelpMilesForPolygonZkEvmRsEthHoldings",
    elpKey: "elPointsForPolygonZkEvmRsEthHoldings",
    isComingSoon: false,
    tags: ["Polygon zkEVM"],
    icon: "/assets/chains/polygon.svg",
  },
  {
    title: "Kim",
    kelpKey: "kelpMilesForKim",
    elpKey: "elPointsForKim",
    isComingSoon: false,
    tags: ["Mode"],
    icon: "/assets/platforms/kim.jpg",
  },
  {
    title: "Karak",
    kelpKey: "kelpMilesForKarak",
    elpKey: "elPointsForKarak",
    isComingSoon: false,
    tags: ["Ethereum"],
    icon: "/assets/platforms/karak.svg",
  },
  {
    title: "Stella",
    kelpKey: "kelpMilesForStella",
    elpKey: "elPointsForStella",
    isComingSoon: false,
    tags: ["Arbitrum"],
    icon: "/assets/platforms/stella.jpg",
  },
  {
    title: "BlastAmbient",
    kelpKey: "kelpMilesForBlastAmbient",
    elpKey: "elPointsForBlastAmbient",
    isComingSoon: false,
    tags: ["Blast"],
    icon: "/assets/chains/ambient.png",
  },
  {
    title: "ZeroLend",
    kelpKey: "kelpMilesForZeroLend",
    elpKey: "elPointsForZeroLend",
    isComingSoon: false,
    tags: ["Ethereum"],
    icon: "/assets/platforms/zerolend.jpg",
  },
  {
    title: "Nile wrsETH",
    kelpKey: "kelpMilesForNileWRsEth",
    elpKey: "elPointsForNileWRsEth",
    isComingSoon: false,
    tags: ["Linea"],
    icon: "/assets/platforms/nile.jpg",
  },
  {
    title: "Blast rsETH Holdings",
    kelpKey: "kelpMilesForBlastRsEthHoldings",
    elpKey: "elPointsForBlastRsEthHoldings",
    isComingSoon: false,
    tags: ["Blast"],
    icon: "/assets/chains/blast.png",
  },
  {
    title: "Blast wrsETH Holdings",
    kelpKey: "kelpMilesForBlastWRsEthHoldings",
    elpKey: "elPointsForBlastWRsEthHoldings",
    isComingSoon: false,
    tags: ["Blast"],
    icon: "/assets/chains/blast.png",
  },
  {
    title: "Sturdy ETH",
    kelpKey: "kelpMilesForSturdyEth",
    elpKey: "elPointsForSturdyEth",
    isComingSoon: false,
    tags: ["Ethereum"],
    icon: "/assets/platforms/sturdy.jpg",
  },
  {
    title: "Sturdy Mode",
    kelpKey: "kelpMilesForSturdyMode",
    elpKey: "elPointsForSturdyMode",
    isComingSoon: false,
    tags: ["Mode"],
    icon: "/assets/platforms/sturdy.jpg",
  },
  {
    title: "Bonus EL Points Campaign",
    kelpKey: "kelpMilesForBonusELpointsETH",
    elpKey: "elPointsForBonusELpointsETH",
    isComingSoon: false,
    tags: ["Ethereum", "Arbitrum"],
    icon: "/assets/kelp_icon.svg",
  },
  {
    title: "INIT Blast",
    kelpKey: "kelpMilesForInitBlast",
    elpKey: "elPointsForInitBlast",
    isComingSoon: false,
    tags: ["Blast"],
    icon: "/assets/chains/blast.png",
  },
  {
    title: "zkSync rsETH Holdings",
    kelpKey: "kelpMilesForZkSyncRsEthHoldings",
    elpKey: "elPointsForZkSyncRsEthHoldings",
    isComingSoon: false,
    tags: ["zkSync"],
    icon: "/assets/chains/zksync.svg",
  },
  {
    title: "zkSync wrsETH Holdings",
    kelpKey: "kelpMilesForZkSyncWRsEthHoldings",
    elpKey: "elPointsForZkSyncWRsEthHoldings",
    isComingSoon: false,
    tags: ["zkSync"],
    icon: "/assets/chains/zksync.svg",
  },
  {
    title: "XLayer wrsETH Holdings",
    kelpKey: "kelpMilesForXLayerWRsEthHoldings",
    elpKey: "elPointsForXLayerWRsEthHoldings",
    isComingSoon: false,
    tags: ["XLayer"],
    icon: "/assets/chains/xlayer.svg",
  },
  {
    title: "Scroll Referrer",
    kelpKey: "kelpMilesForScrollReferrer",
    elpKey: null,
    isComingSoon: false,
    tags: ["Scroll"],
    icon: "/assets/chains/scroll.svg",
  },
  {
    title: "Base Referrer",
    kelpKey: "kelpMilesForBaseReferrer",
    elpKey: null,
    isComingSoon: false,
    tags: ["Base"],
    icon: "/assets/chains/base.svg",
  },
  {
    title: "Arbitrum Referrer",
    kelpKey: "kelpMilesForArbitrumReferrer",
    elpKey: null,
    isComingSoon: false,
    tags: ["Arbitrum"],
    icon: "/assets/chains/arbitrum.svg",
  },
  {
    title: "Mode Referrer",
    kelpKey: "kelpMilesForModeReferrer",
    elpKey: null,
    isComingSoon: false,
    tags: ["Mode"],
    icon: "/assets/chains/mode.svg",
  },
  {
    title: "Blast Referrer",
    kelpKey: "kelpMilesForBlastReferrer",
    elpKey: null,
    isComingSoon: false,
    tags: ["Blast"],
    icon: "/assets/chains/blast.svg",
  },
  {
    title: "Linea Referrer",
    kelpKey: "kelpMilesForBlastReferrer",
    elpKey: null,
    isComingSoon: false,
    tags: ["Linea"],
    icon: "/assets/chains/linea.svg",
  },
  {
    title: "Optimism Referrer",
    kelpKey: "kelpMilesForOptimismReferrer",
    elpKey: null,
    isComingSoon: false,
    tags: ["Optimism"],
    icon: "/assets/chains/optimism.svg",
  },
  {
    title: "Olive",
    kelpKey: "kelpMilesForOliveMainnet",
    elpKey: "elPointsForOliveMainnet",
    isComingSoon: false,
    tags: ["Ethereum", "Arbitrum"],
    icon: "/assets/platforms/olive.svg",
  },
  {
    title: "Blackwing",
    kelpKey: "kelpMilesForBlackwingMainnet",
    elpKey: "elPointsForBlackwingMainnet",
    isComingSoon: false,
    tags: ["Ethereum"],
    icon: "/assets/platforms/blackwing.jpg",
  },
  {
    title: "Layerbank wrsETH Holdings",
    kelpKey: "kelpMilesForLayerbankWRsEthHoldings",
    elpKey: "elPointsForLayerbank",
    isComingSoon: false,
    tags: ["Linea", "Scroll", "Mode"],
    icon: "/assets/platforms/layerbank.jpg",
  },
  {
    title: "Fenix Finance",
    kelpKey: "kelpMilesForFenix",
    elpKey: "elPointsForFenix",
    isComingSoon: false,
    tags: ["Blast"],
    icon: "/assets/platforms/fenix.svg",
  },
  {
    title: "Pencils",
    kelpKey: "kelpMilesForPencils",
    elpKey: "elPointsForPencils",
    isComingSoon: false,
    tags: ["Scroll"],
    icon: "/assets/platforms/pencils.jpg",
  },
  {
    title: "Ion",
    kelpKey: "kelpMilesForIon",
    elpKey: "elPointsForIon",
    isComingSoon: false,
    tags: ["Ethereum"],
    icon: "/assets/platforms/ion.jpg",
  },
  {
    title: "Linea Referrer",
    kelpKey: "kelpMilesForLineaReferrer",
    elpKey: null,
    isComingSoon: false,
    tags: ["Linea"],
    icon: "/assets/chains/linea.svg",
  },
  {
    title: "ETH/rsETH Holdings",
    kelpKey: "kelpMilesForEthRsEthHoldings",
    elpKey: "elPointsForEthRsEthHoldings",
    isComingSoon: false,
    tags: ["Ethereum"],
    icon: "/assets/platforms/eth_rseth.png",
  },
  {
    title: "XLayer rsETH Holdings",
    kelpKey: "kelpMilesForXLayerRsEthHoldings",
    elpKey: "elPointsForXLayerRsEthHoldings",
    isComingSoon: false,
    tags: ["XLayer"],
    icon: "/assets/chains/xlayer.svg",
  },
  {
    title: "Velodrome",
    kelpKey: "kelpMilesForVelodrome",
    elpKey: "elPointsForVelodrome",
    isComingSoon: false,
    tags: ["Optimism"],
    icon: "/assets/platforms/velorome.webp",
  },
  {
    title: "Aerodrome",
    kelpKey: "kelpMilesForAerodrome",
    elpKey: "elPointsForAerodrome",
    isComingSoon: false,
    tags: ["Base"],
    icon: "/assets/platforms/aerodrome.jpeg",
  },
  {
    title: "BladeSwap",
    kelpKey: "kelpMilesForBladeSwap",
    elpKey: "elPointsForBladeSwap",
    isComingSoon: false,
    tags: ["Blast"],
    icon: "/assets/platforms/bladeswap.png",
  },
  {
    title: "Blaster",
    kelpKey: "kelpMilesForBlaster",
    elpKey: "elPointsForBlaster",
    isComingSoon: false,
    tags: ["Blast"],
    icon: "/assets/platforms/blasterswap.webp",
  },
  {
    title: "Dolo",
    kelpKey: "kelpMilesForDolo",
    elpKey: "elPointsForDolo",
    isComingSoon: false,
    tags: ["Arbitrum"],
    icon: "/assets/platforms/dolomite.jpeg",
  },
  {
    title: "Ionic",
    kelpKey: "kelpMilesForIonic",
    elpKey: "elPointsForIonic",
    isComingSoon: false,
    tags: ["Mode"],
    icon: "/assets/platforms/ionic.png",
  },
  {
    title: "Lyra",
    kelpKey: "kelpMilesForLyra",
    elpKey: "elPointsForLyra",
    isComingSoon: false,
    tags: ["Ethereum", "Arbitrum"],
    icon: "/assets/platforms/lyra.png",
  },
  {
    title: "ZeroLend Linea",
    kelpKey: "kelpMilesForZeroLendLinea",
    elpKey: "elPointsForZeroLendLinea",
    isComingSoon: false,
    tags: ["Linea"],
    icon: "/assets/platforms/zerolend.jpg",
  },
  {
    title: "Notional",
    kelpKey: "kelpMilesForNotional",
    elpKey: "elPointsForNotional",
    isComingSoon: false,
    tags: ["Ethereum"],
    icon: "/assets/platforms/notional.jpeg",
  },
  {
    title: "Pancake",
    kelpKey: "kelpMilesForPancake",
    elpKey: "elPointsForPancake",
    isComingSoon: false,
    tags: ["Arbitrum"],
    icon: "/assets/platforms/pancake.png",
  },
  {
    title: "Pendle Ethereum",
    kelpKey: "kelpMilesForPendleEthereum",
    elpKey: "elPointsForPendleEthereum",
    isComingSoon: false,
    tags: ["Ethereum"],
    icon: "/assets/platforms/pendle.svg",
  },
  {
    title: "Nuri",
    kelpKey: "kelpMilesForNuri",
    elpKey: "elPointsForNuri",
    isComingSoon: false,
    tags: ["Scroll"],
    icon: "/assets/platforms/nuri.png",
  },
  {
    title: "Mendi",
    kelpKey: "kelpMilesForMendi",
    elpKey: "elPointsForMendi",
    isComingSoon: false,
    tags: ["Linea"],
    icon: "/assets/platforms/mendi.png",
  },
  {
    title: "Compound",
    kelpKey: "kelpMilesForCompound",
    elpKey: "elPointsForCompound",
    isComingSoon: false,
    tags: ["Ethereum"],
    icon: "/assets/platforms/compound.png",
  },
  {
    title: "XLayer Referrer",
    kelpKey: "kelpMilesForXlayerReferrer",
    elpKey: null,
    isComingSoon: false,
    tags: ["XLayer"],
    icon: "/assets/chains/xlayer.svg",
  },
  {
    title: "zkSync Referrer",
    kelpKey: "kelpMilesForZksyncReferrer",
    elpKey: null,
    isComingSoon: false,
    tags: ["zkSync"],
    icon: "/assets/chains/zksync.svg",
  },
  {
    title: "Teahouse Linea",
    kelpKey: "kelpMilesForTeahouseLinea",
    elpKey: "elPointsForTeahouseLinea",
    isComingSoon: false,
    tags: ["Linea"],
    icon: "/assets/platforms/teahouse.png",
  },
  {
    title: "Splice",
    kelpKey: "kelpMilesForSplice",
    elpKey: "elPointsForSplice",
    isComingSoon: false,
    tags: ["Mode"],
    icon: "/assets/platforms/splice.png",
  },
  {
    title: "Astherus",
    kelpKey: "kelpMilesForAstherus",
    elpKey: "elPointsForAstherus",
    isComingSoon: false,
    tags: ["Ethereum"],
    icon: "/assets/platforms/astherus.png",
  },
  {
    title: "Bracket",
    kelpKey: "kelpMilesForBracket",
    elpKey: "elPointsForBracket",
    isComingSoon: false,
    tags: ["Ethereum"],
    icon: "/assets/platforms/bracket.png",
  },
  {
    title: "Harmonix",
    kelpKey: "kelpMilesForHarmonix",
    elpKey: "elPointsForHarmonix",
    isComingSoon: false,
    tags: ["Arbitrum"],
    icon: "/assets/platforms/harmonix.png",
  },
  {
    title: "Pendle Ethereum (Sept)",
    kelpKey: "kelpMilesForPendleEthereumSept",
    elpKey: "elPointsForPendleEthereumSept",
    isComingSoon: false,
    tags: ["Ethereum"],
    icon: "/assets/platforms/pendle.svg",
  },
  {
    title: "Curve WeETH",
    kelpKey: "kelpMilesForCurveWeEth",
    elpKey: "elPointsForCurveWeEth",
    isComingSoon: false,
    tags: ["Ethereum"],
    icon: "/assets/platforms/curve.svg",
  },
  {
    title: "Pendle Arbitrum (Sept)",
    kelpKey: "kelpMilesForPendleArbSept",
    elpKey: "elPointsForPendleArbSept",
    isComingSoon: false,
    tags: ["Arbitrum"],
    icon: "/assets/platforms/pendle.svg",
  },
  {
    title: "Zircuit (Aug)",
    kelpKey: "kelpMilesForZircuitrsETHPendleAug",
    elpKey: "elPointsForZircuitrsETHPendleAug",
    isComingSoon: false,
    tags: ["Ethereum"],
    icon: "/assets/platforms/zircuit.png",
  },
];

export const INITIAL_REWARS_DATA = {
  kelpMilesForRsEthHoldings: "",
  bonus100kKelpMiles: "",
  kelpMiles: "", // TOTAL
  elPoints: "", // TOTAL
  kmForLastDay: "",
  elPointsForLastDay: "",
  elPointsForRsEthHoldings: "",
  elPointsForPendle: "",
  eqbVoters: "",
  penpieVoters: "",
  kelpMilesForReferrer: "",
  elPointsMissing: "",
  kelpMilesForBonusELpointsETH: "",
  elPointsForBonusELpointsETH: "",
  kelpMilesForPendleEthereumSept: "",
  kelpMilesForZircuitrsETHPendle: "",
};

export const BRIDGE_OPTIONS = [
  {
    to: "Arbitrum",
    icon: "/assets/bridge/arbitrum.svg",
    link: "https://stargate.finance/transfer",
    isExternal: true,
  },
  {
    to: "Optimism",
    icon: "/assets/chains/optimism.svg",
    link: "https://stargate.finance/transfer",
    isExternal: true,
  },
  {
    to: "Manta Pacific",
    icon: "/assets/bridge/manta-pacific.svg",
    link: "https://stargate.finance/transfer",
    isExternal: true,
  },
  {
    to: "Blast",
    icon: "/assets/bridge/blast.svg",
    link: "https://stargate.finance/transfer",
    isExternal: true,
  },
  {
    to: "Mode",
    icon: "/assets/bridge/mode.svg",
    link: "https://stargate.finance/transfer",
    isExternal: true,
  },
  {
    to: "Scroll",
    icon: "/assets/chains/scroll.svg",
    link: "https://stargate.finance/transfer",
    isExternal: true,
  },
  {
    to: "Linea",
    icon: "/assets/chains/linea.svg",
    link: "https://stargate.finance/transfer",
    isExternal: true,
  },
  {
    to: "Base",
    icon: "/assets/chains/base.svg",
    link: "https://stargate.finance/transfer",
    isExternal: true,
  },
  {
    to: "zkSync Era",
    icon: "/assets/chains/zksync.svg",
    link: "https://stargate.finance/transfer",
    isExternal: true,
  },
];

export const STADER_CLIENT_ID = "8e156db4-59ab-46e8-9ef4-17aac2d9fb96";

export type STEPS_TYPE = {
  text: string;
  pair_from?: string;
  pair_to?: string;
};

export const STEPS_STADER_LEDGER_TXS: Array<STEPS_TYPE> = [
  { text: "Initiate stake", pair_from: "ETH", pair_to: "ETHx" },
  { text: "Approve restake" },
  { text: "Initiate restake", pair_from: "ETHx", pair_to: "rsETH" },
];

export const chainDefiSectionMapping: IIndexableNumber = {
  1: "Ethereum",
  10: "Optimism",
  196: "Ethereum",
  324: "zkSync",
  8453: "Base",
  42161: "Arbitrum",
  34443: "Mode",
  59144: "Linea",
  81457: "Blast",
  534352: "Scroll",
};
