import { createSlice } from "@reduxjs/toolkit";

export interface EventStateProps {
  emitter: {
    id: string;
    name: string;
    data: any;
  };
}

export const initialEventState: EventStateProps = {
  emitter: {
    id: "",
    name: "",
    data: null,
  },
};

export const eventSlice = createSlice({
  name: "event",
  initialState: initialEventState,
  reducers: {
    updateEventData: (state: any, action: any) => {
      for (const [key, value] of Object.entries(action.payload)) {
        state[key] = value;
      }
    },
  },
});

export const { updateEventData }: any = eventSlice.actions;

export default eventSlice.reducer;
