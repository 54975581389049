import React from "react";
import { Flex, Button, Show } from "@chakra-ui/react";
import BaseImage from "@components/BaseImage";

interface CloseIconProps {
  onClose: () => void;
}

const CloseIcon = ({ onClose }: CloseIconProps) => {
  return (
    <Flex
      w="100%"
      textAlign="right"
      mb="0.5rem"
      justifyContent={{ base: "center", md: "flex-end" }}
    >
      <Button
        variant="unstyled"
        display="flex"
        p={{ base: "1.5rem 0", md: 0 }}
        height="24px"
        onClick={onClose}
        _focus={{
          boxShadow: "none",
        }}
      >
        <Show above="md">
          <BaseImage
            height={24}
            width={24}
            alt="close"
            src="/assets/close.svg"
          />
        </Show>
        <Show below="md">
          <BaseImage
            height={4}
            width={40}
            alt="close"
            src="/assets/close_mobile.svg"
          />
        </Show>
      </Button>
    </Flex>
  );
};

export default CloseIcon;
