import { store, updateEventData } from "./store";
import * as Sentry from "@sentry/browser";
import { IIndexable } from "@constants/common";
import { UserRejectedRequestError } from "viem";

import {
  arbitrum,
  base,
  blast,
  mainnet,
  manta,
  mode,
  optimism,
  scroll,
  linea,
  zkSync,
  xLayer,
} from "viem/chains";

export const sentryErrorLog = (error: unknown) => {
  Sentry.captureException(error);
};

export const getURLParams = () => {
  const queryString =
    typeof window === "undefined"
      ? ""
      : getQueryString((window as any)?.location?.search);
  const origin = typeof window === "undefined" ? "" : window?.location?.origin;
  return { queryString, origin };
};

export const getQueryString = (obj: any) => {
  return new URLSearchParams(obj).toString();
};

export function isErrorRejectedByUser(error: any) {
  const errorMessage = error ? JSON.stringify(error) : "";
  const isrejectedError = [
    "metamask tx signature: user denied transaction signature.",
    "tx signature: user denied transaction signature.",
    "user rejected",
    "user rejected the transaction",
    "User canceled",
    "transaction declined",
  ].some((errMessage) => errorMessage.toLowerCase().includes(errMessage));
  return error instanceof UserRejectedRequestError || isrejectedError;
}

export const copyToClipboard = (value: any) =>
  navigator.clipboard.writeText(value);

export const shortenAddress = (
  address: string,
  width: undefined | number = undefined,
  startingAddressLetters = 10,
  endingAddressLetters = 4
) => {
  let startAddressLetters = startingAddressLetters;
  let endAddressLetters = endingAddressLetters;

  if (width) {
    if (width < 235) {
      startAddressLetters = 4;
      endAddressLetters = 2;
    }
  }

  if (address.length < startAddressLetters + endAddressLetters) {
    return address;
  }

  return `${address.substring(0, startAddressLetters)}...${address.substring(
    address.length - endAddressLetters
  )}`;
};

export const emitReduxEvent = (name: string, data?: any) => {
  const id = new Date().getTime();
  store.dispatch(
    updateEventData({
      emitter: {
        id,
        name,
        data,
      },
    })
  );
};

const getValue = (
  isFloor: boolean,
  isSlice: boolean,
  value: number,
  maxFractionDigits: number
) => {
  if (isFloor) {
    return Math.floor(value);
  } else if (isSlice) {
    const updatedValue = value.toString();

    if (updatedValue.indexOf(".") !== -1) {
      return Number(
        updatedValue.slice(0, updatedValue.indexOf(".") + maxFractionDigits + 1)
      );
    }
    return value;
  }
  return value;
};

export const formatNumber = (
  value: number,
  isFloor: boolean = true,
  maxFractionDigits = 4,
  isSlice: boolean = false
) => {
  const updatedValue = getValue(isFloor, isSlice, value, maxFractionDigits);
  return updatedValue.toLocaleString("en", {
    minimumFractionDigits: 0,
    maximumFractionDigits: maxFractionDigits,
  });
};

export const numberToCurrency = (value: string, precision = 2) => {
  return Math.abs(Number(value)) >= 1.0e9
    ? +(Math.abs(Number(value)) / 1.0e9).toFixed(precision) + "B"
    : Math.abs(Number(value)) >= 1.0e6
    ? +(Math.abs(Number(value)) / 1.0e6).toFixed(precision) + "M"
    : Math.abs(Number(value)) >= 1.0e3
    ? +(Math.abs(Number(value)) / 1.0e3).toFixed(precision) + "K"
    : Math.abs(Number(value)).toString();
};

export const isFloat = (n: number) => {
  return Number(n) === n && n % 1 !== 0;
};

export const toStringPrecision = (num: number) => {
  let res = "";

  if (Math.abs(num) < 1.0) {
    const e = parseInt(num.toString().split("e-")[1]);
    if (e) {
      num *= Math.pow(10, e - 1);
      res = "0." + new Array(e).join("0") + num.toString().substring(2);
    }
  } else {
    let e = parseInt(num.toString().split("+")[1]);
    if (e > 20) {
      e -= 20;
      num /= Math.pow(10, e);
      res = num + new Array(e + 1).join("0");
    }
  }
  res = res.includes(".") ? Number(res).toFixed(8) : res;
  return res || num.toString();
};

export const trackEvent = (eventName: string, eventParams: any = {}) => {
  const { wallet, user, global } = store.getState();
  const { walletAddress, walletName } = wallet;
  const { tokenXAmount, tokenAmount, tokenSymbol, referralId } = user;
  const { selectedChain } = global;

  const params = {
    ...eventParams,
    walletAddress,
    walletName,
    tokenAmount,
    tokenXAmount,
    tokenSymbol,
    dapp: "kelp",
    referralId,
    chainId: selectedChain.id || "",
    chainName: selectedChain.name || "",
  };

  // GTAG
  try {
    (window as any).dataLayer?.push({
      event: eventName,
      ...params,
    });
  } catch (e) {
    // eslint-disable-next-line no-console
    console.log("Error Logging GTAG ", e);
    sentryErrorLog(e);
  }

  // Rudder Stack
  try {
    (window as any).rudderanalytics?.track(eventName, {
      ...params,
    });
  } catch (e) {
    // eslint-disable-next-line no-console
    console.log("Error Logging rudderstack ", e);
    sentryErrorLog(e);
  }
};

export const validateEmail = (email: string) => {
  const emailReg = /^([\w-.]+@([\w-]+\.)+[\w-]{2,4})?$/;
  return emailReg.test(email);
};

export const IconMapping: IIndexable = {
  coins: "/assets/landingPage/coins.svg",
  user_search: "/assets/landingPage/user_search.svg",
  arm_coins: "/assets/landingPage/user_coin.svg",
};

export const chainIconMapping: IIndexable = {
  [mainnet.id]: "/assets/chains/ethereum.svg",
  [arbitrum.id]: "/assets/chains/arbitrum.svg",
  [manta.id]: "/assets/chains/manta-pacific.svg",
  [mode.id]: "/assets/chains/mode.svg",
  [blast.id]: "/assets/chains/blast.png",
  [scroll.id]: "/assets/chains/scroll.svg",
  [optimism.id]: "/assets/chains/optimism.svg",
  [base.id]: "/assets/chains/base.svg",
  [linea.id]: "/assets/chains/linea.svg",
  [zkSync.id]: "/assets/chains/zksync.svg",
  [xLayer.id]: "/assets/chains/xlayer.svg",
};

export const getBaseUrl = () => {
  const subRoute = process.env.NEXT_PUBLIC_SUB_ROUTE || "";
  let baseUrl = `${subRoute ? subRoute + "/" : "/"}`;

  // if (typeof document !== "undefined") {
  //   const { pathname } = window.location;
  //   const ipfsMatch = /.*\/Qm\w{44}\//.exec(pathname);

  //   baseUrl = ipfsMatch ? ipfsMatch[0] : baseUrl;
  // }
  return baseUrl;
};

export const getUrlForStaticImage = (src: any) => {
  const baseUrl = getBaseUrl();
  const baseUrlWithoutTrailingSlash = baseUrl.substring(0, baseUrl.length - 1);
  const trimmedSrc = src.src;

  return `${baseUrlWithoutTrailingSlash}${trimmedSrc}`;
};

export const getUrlForLocalImage = (src: string) => {
  const baseUrl = getBaseUrl();
  const baseUrlWithoutTrailingSlash = baseUrl.substring(0, baseUrl.length - 1);

  return `${baseUrlWithoutTrailingSlash}${src}`;
};

export const DefiTagImageMap: Record<string, string> = {
  Ethereum: "/assets/chains/ethereum.svg",
  "Polygon zkEVM": "/assets/chains/polygon.svg",
  Arbitrum: "/assets/chains/arbitrum.svg",
  Blast: "/assets/chains/blast.png",
  Mode: "/assets/chains/mode.svg",
  Scroll: "/assets/chains/scroll.svg",
  Optimism: "/assets/chains/optimism.svg",
  Base: "/assets/chains/base.svg",
  Linea: "/assets/chains/linea.svg",
  XLayer: "/assets/chains/xlayer.svg",
};

export const getImageWithSubRoute = (path: string) => {
  const SUB_ROUTE = process.env.NEXT_PUBLIC_SUB_ROUTE;

  return SUB_ROUTE ? `${SUB_ROUTE}${path}` : path;
};

export const chunkArray = (array: any, chunkSize: number) => {
  const result = [];
  for (let i = 0; i < array.length; i += chunkSize) {
    const chunk = array.slice(i, i + chunkSize);
    result.push(chunk);
  }
  return result;
};
