import { useEffect, useState } from "react";
import { useAccount } from "wagmi";

const useLedgerLive = () => {
  const [isLedgerInjected, setIsLedgerInjected] = useState(false);
  const { connector } = useAccount();

  const isLedgerConnected = connector?.id === "com.ledger";

  useEffect(() => {
    if (window) {
      setIsLedgerInjected(window.ethereum?.isLedgerLive ?? false);
    }
  }, []);

  const isLedgerLiveENV = isLedgerInjected && isLedgerConnected;

  return { isLedgerConnected, isLedgerInjected, isLedgerLiveENV };
};

export default useLedgerLive;
