import { IIndexableTransaction, TransactionType } from "../../constants/types";

export const TransactionIconMapping: IIndexableTransaction = {
  [TransactionType.SUCCESS]: {
    icon: "/assets/transactions/success.svg",
  },
  [TransactionType.ERROR]: {
    icon: "/assets/transactions/error.svg",
  },
  [TransactionType.WARNING]: {
    icon: "/assets/transactions/warning.svg",
  },
  [TransactionType.INFO]: {
    icon: "/assets/transactions/info.svg",
  },
};
