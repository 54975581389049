import { createSlice } from "@reduxjs/toolkit";

export interface WalletStateProps {
  isConnected: boolean;
  connectorID: string | null;
  provider: any;
  switchNetwork: any;
  config: any;
  walletAddress: string; // anallytics
  walletName: string; // analytics
  connectKitObj: any;
}

export const initialWalletState: WalletStateProps = {
  isConnected: false,
  provider: null,
  connectorID: null,
  switchNetwork: null,
  config: null,
  walletAddress: "", // anallytics
  walletName: "", // analytics
  connectKitObj: null,
};

export const walletSlice = createSlice({
  name: "wallet",
  initialState: initialWalletState,
  reducers: {
    updateWalletData: (state: any, action: any) => {
      for (const [key, value] of Object.entries(action.payload)) {
        state[key] = value;
      }
    },
    clearWalletData: (state: any) => {
      for (const [key, value] of Object.entries(initialWalletState)) {
        if (!["isLoading"].includes(key)) {
          state[key] = value;
        }
      }
    },
  },
});

// Action creators are generated for each case reducer function
export const { updateWalletData, clearWalletData }: any = walletSlice.actions;
export default walletSlice.reducer;
