import React from "react";
import { Text } from "@chakra-ui/react";
import { Connector } from "wagmi";

import ModalDrawer from "../ModalDrawer";

// components
// import TermsAndCondition from "./components/TermsAndCondition";
import WalletList from "./components/WalletList";
import TermsAndCondition from "./components/TermsAndCondition";

interface WalletMenuType {
  isOpen: boolean;
  closeModal(): void;
  onConnectWallet(data: Connector): void;
}

const WalletConnectModal = ({
  isOpen,
  closeModal,
  onConnectWallet,
}: WalletMenuType) => {
  // const [isChecked, setChecked] = useState(false);

  // useEffect(() => {
  //   if (isOpen) {
  //     setChecked(false);
  //   }
  // }, [isOpen]);

  // useEffect(() => {
  //   if (isOpen) {
  //     const connectorSection = document.querySelectorAll(
  //       '#__CONNECTKIT__ div[role="dialog"] > div:last-child > div:last-child > div:last-child > div > div > div > div:first-child'
  //     );

  //     const connectorSectionElement = Array.from(connectorSection)[0];
  //     connectorSectionElement?.classList.add("enable");
  //     // if (isChecked) {
  //     //   connectorSectionElement?.classList.add("enable");
  //     // } else {
  //     //   connectorSectionElement?.classList.remove("enable");
  //     // }
  //   }
  //   // else {
  //   //   setChecked(false);
  //   // }
  // }, [isOpen]);

  const handleClose = () => {
    // setChecked(false);
    closeModal();
  };

  // const toggle = () => {
  //   onTermsSelectChange(!isChecked);
  //   setChecked((prev) => !prev);
  // };

  return (
    <ModalDrawer
      isOpen={isOpen}
      closeAlert={handleClose}
      bodyProps={{ mt: "2rem" }}
      header={<Text width="100%">Select wallet</Text>}
      contentProps={{ p: { base: "2.5rem 1rem 1rem", md: "2.5rem 3rem" } }}
      headerProps={{
        p: "0",
        fontSize: "1.5rem",
        fontWeight: "600",
        textAlign: "center",
      }}
    >
      <>
        <TermsAndCondition />
        <WalletList onConnectWallet={onConnectWallet} />
      </>
    </ModalDrawer>
  );
};

export default WalletConnectModal;
