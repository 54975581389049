import React from "react";
import { Link, Button, Center } from "@chakra-ui/react";

import { VIEW_ADDRESS_ON_SCANNER } from "@constants/analytics";
import { trackEvent } from "utils";

interface ScannerLinkProps {
  scannerLink: string;
}

const ScannerLink = ({ scannerLink }: ScannerLinkProps) => {
  return (
    <Center>
      <Link
        fontWeight={{
          base: "700",
          md: "600",
        }}
        fontSize={{
          base: "10px",
          md: "14px",
        }}
        cursor="pointer"
        href={scannerLink}
        target="_blank"
        rel="noreferrer"
        _hover={{
          borderBottom: "none",
        }}
        onClick={() => {
          trackEvent(VIEW_ADDRESS_ON_SCANNER);
        }}
      >
        <Button
          p="12px 45px"
          variant="unstyled"
          borderRadius="8px"
          bg="textPrimary"
          color="white"
          h="48px"
          _dark={{
            bg: "white",
            color: "textPrimary",
          }}
        >
          View on explorer
        </Button>
      </Link>
    </Center>
  );
};

export default ScannerLink;
