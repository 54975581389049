import { createSlice } from "@reduxjs/toolkit";

export interface ModalStateProps {
  modalType: string;
  isMobileView: boolean;
  errorMessage?: string;
}

export const initialModalState: ModalStateProps = {
  modalType: "",
  isMobileView: false,
  errorMessage: "",
};

export const modalSlice = createSlice({
  name: "modal",
  initialState: initialModalState,
  reducers: {
    updateModalData: (state: any, action: any) => {
      for (const [key, value] of Object.entries(action.payload)) {
        state[key] = value;
      }
    },
    clearModallData: (state: any) => {
      for (const [key, value] of Object.entries(initialModalState)) {
        state[key] = value;
      }
    },
  },
});

// Action creators are generated for each case reducer function
export const { updateModalData, clearModallData }: any = modalSlice.actions;
export default modalSlice.reducer;
