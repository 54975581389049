import { useEffect } from "react";
import { useConnect, useConnectors } from "wagmi";
import useLedgerLive from "./useLedgerLive";

const useAutoConnect = () => {
  const connectors = useConnectors();
  const { connect } = useConnect();
  const { isLedgerInjected } = useLedgerLive();

  useEffect(() => {
    if (isLedgerInjected) {
      const ledger = connectors.find((conn) => {
        return conn.id === "com.ledger";
      });

      if (!ledger) {
        return;
      }

      connect(
        { connector: ledger },
        {
          onError: (error) => {
            // eslint-disable-next-line no-console
            console.log("error", error);
          },
          onSuccess: (data) => {
            // eslint-disable-next-line no-console
            console.log("success", data);
          },
        }
      );
    }
  }, [isLedgerInjected, connect, connectors]);
};

export default useAutoConnect;
