import { chainDefiSectionMapping } from "@constants/common";
import { DeFiRow } from "@container/pages/defi/type";
import { useMemo } from "react";
import useDappChain from "./useDappChain";
import defiJSON from "@files/defi.json";

const useDefiData = () => {
  const { chain } = useDappChain();
  const { pools } = defiJSON;

  const sections = useMemo(() => {
    const sections: { [key: string]: DeFiRow[] } = {};
    pools?.data.forEach((row: any) => {
      if (row.Section) {
        sections[row.Section] = sections[row.Section] || [];
        sections[row.Section].push(row);
      }
    });
    return sections;
  }, [pools?.data]);

  return sections[chainDefiSectionMapping[chain.id]]?.[0] || {};
};

export default useDefiData;
