import { createSlice } from "@reduxjs/toolkit";

export type TGlobalState = {
  selectedChain: {
    name?: string;
    id?: number;
  };
};

export const initialState: TGlobalState = {
  selectedChain: {
    id: undefined,
    name: undefined,
  },
};

export const globalSlice = createSlice({
  name: "global",
  initialState: initialState,
  reducers: {
    updateSelectedChain: (state, action) => {
      state.selectedChain = action.payload;
    },
  },
});

export const { updateSelectedChain }: any = globalSlice.actions;

export default globalSlice.reducer;
