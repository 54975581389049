import { useAccount, useBalance, useReadContracts } from "wagmi";
import { useIdle } from "react-use";

import { toFixedWithoutRounding } from "../constants/common";

import useDappConfig from "./useDappConfig";
import useDappChain from "./useDappChain";
import { formatUnits } from "viem";

const useTokenBalance = () => {
  const isIdle = useIdle(150e3);
  const { address: walletAddress } = useAccount();

  const { config, vaultsContractConfig } = useDappConfig();
  const { chain } = useDappChain();
  const tokens = config.tokens;
  const tokenKeys = Object.keys(tokens);

  const balancesForTokens = [
    ...tokenKeys.map((token) => tokens[token]),
    { ...config.xtoken },
    { ...vaultsContractConfig.xToken },
  ];

  const { data: tokensBalances } = useReadContracts({
    contracts: balancesForTokens.map((token) => ({
      address: token.address,
      abi: token.abi,
      functionName: "balanceOf",
      args: [walletAddress],
      chain,
    })),
    query: {
      enabled: !isIdle && !!walletAddress,
      refetchInterval: 5_000,
    },
  });

  const { data: nativeTokenBalance } = useBalance({
    address: walletAddress,
    query: {
      enabled: !isIdle && !!walletAddress,
      refetchInterval: 5_000,
      initialData: {
        decimals: config.nativeToken.decimals,
        formatted: "0",
        symbol: config.nativeToken.symbol,
        value: 0n,
      },
    },
  });

  const supportedTokenBalances: { [x: string]: number } = tokensBalances
    ? tokensBalances
        .map((res) => res.result! as bigint)
        .reduce((acc, curr, index) => {
          return {
            ...acc,
            [balancesForTokens[index].symbol]: +toFixedWithoutRounding(
              curr ? formatUnits(curr, balancesForTokens[index].decimals) : "0",
              8
            ),
          };
        }, {})
    : {};

  return {
    tokensBalances: tokensBalances ? supportedTokenBalances : {},
    nativeTokenBalance: +toFixedWithoutRounding(
      nativeTokenBalance?.formatted || "0",
      8
    ),
    xTokenTokenBalance: supportedTokenBalances[config.xtoken.symbol],
    xTokenVaultBalance:
      supportedTokenBalances[vaultsContractConfig.xToken.symbol],
  };
};

export default useTokenBalance;
