export enum TransactionType {
  ERROR = "ERROR",
  SUCCESS = "SUCCESS",
  WARNING = "WARNING",
  INFO = "INFO",
}

export type IIndexableTransaction = {
  [key in TransactionType]: {
    icon: any;
  };
};

export type SubHeaderType = {
  key: string;
  name: string;
  link: string;
};

export type SideBarDataType = {
  title: string;
  key: string;
  isComingSoon?: boolean;
  isDisabled?: boolean;
  link?: string;
  isExternal?: boolean;
  data?: SideBarDataType[];
  eventName?: string;
  isNew?: boolean;
  isLedgerEnabld?: boolean;
};
